const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../components/Login.vue'),
    meta: {
      title: 'EPIC portal - Login',
    },
  },
  {
    path: '/change_password/:token',
    name: 'change_password',
    component: () => import('../components/Login.vue'),
    meta: {
      title: 'EPIC portal - Change Password',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../components/Dashboard/Dashboard.vue'),
    meta: {
      title: 'EPIC portal - Dashboard',
      breadCrumb: { en: 'Dashboard / Overview', nl: 'Dashboard / Overzicht' },
      requiresAuth: true,
    },
  },
  {
    path: '/search_results/:type?',
    name: 'search_results',
    component: () => import('../components/Global/SearchResults.vue'),
    meta: {
      title: 'EPIC portal - Search',
      breadCrumb: { en: 'Search Results', nl: 'Zoek resultaten' },
      requiresAuth: true,
    },
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('../components/MainPages/Tickets.vue'),
    meta: {
      title: 'EPIC portal - Tickets',
      breadCrumb: { en: 'Tickets', nl: 'Tickets' },
      requiresAuth: true,
    },
    children: [
      {
        path: 'new',
        name: 'new_ticket',
        component: () => import('../components/Tickets/NewTicket.vue'),
        meta: {
          title: 'EPIC portal - Tickets',
          breadCrumb: { en: 'New', nl: 'Nieuw' },
          requiresAuth: true,
        },
        children: [
          {
            path: ':id',
            name: 'request',
            component: () => import('../components/Requests/child_components/Request.vue'),
            meta: {
              title: 'EPIC portal - Tickets',
              breadCrumb: { en: 'New Ticket', nl: 'Nieuwe Ticket' },
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'open',
        name: 'open_tickets',
        component: () => import('../components/Tickets/Tickets.vue'),
        meta: {
          title: 'EPIC portal - Tickets',
          breadCrumb: { en: 'Open', nl: 'Open' },
          requiresAuth: true,
        },
        children: [
          {
            path: ':id',
            name: 'open_ticket_details',
            component: () => import('../components/Tickets/child_components/Ticket.vue'),
            meta: {
              name: true,
              title: 'EPIC portal - Ticket Details',
              breadCrumb: { en: ' ', nl: ' ' },
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'closed',
        name: 'completed_tickets',
        component: () => import('../components/Tickets/CompletedTickets.vue'),
        meta: {
          title: 'EPIC portal - Tickets',
          breadCrumb: { en: 'Closed', nl: 'Afgesloten' },
          requiresAuth: true,
        },
        children: [
          {
            path: ':id',
            name: 'completed_ticket_details',
            component: () => import('../components/Tickets/child_components/Ticket.vue'),
            meta: {
              name: true,
              title: 'EPIC portal - Ticket Details',
              breadCrumb: { en: ' ', nl: ' ' },
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../components/MainPages/Projects.vue'),
    meta: {
      title: 'EPIC portal - Projects',
      breadCrumb: { en: 'Projects', nl: 'Projecten' },
      requiresAuth: true,
    },
    children: [
      {
        path: 'open',
        name: 'open_projects',
        component: () => import('../components/projects/Projects.vue'),
        meta: {
          title: 'EPIC portal - Open Projects',
          breadCrumb: { en: 'Open', nl: 'Open' },
          requiresAuth: true,
        },
        children: [
          {
            path: ':id',
            name: 'open_project_details',
            component: () => import('../components/projects/child_components/Project.vue'),
            meta: {
              name: true,
              title: 'EPIC portal - Project',
              breadCrumb: { en: '', nl: '' },
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'closed',
        name: 'closed_projects',
        component: () => import('../components/projects/Projects.vue'),
        meta: {
          title: 'EPIC portal - Closed Projects',
          breadCrumb: { en: 'Closed', nl: 'Gesloten' },
          requiresAuth: true,
        },
        children: [
          {
            path: ':id',
            name: 'closed_project_details',
            component: () => import('../components/projects/child_components/Project.vue'),
            meta: {
              name: true,
              title: 'EPIC portal - Project',
              breadCrumb: { en: '', nl: '' },
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/services',
    name: 'services',
    component: () => import('../components/MainPages/Services.vue'),
    meta: {
      title: 'EPIC portal - Diensten',
      breadCrumb: { en: 'Services', nl: 'Diensten' },
      requiresAuth: true,
    },
    children: [
      {
        path: 'domain_hosting',
        name: 'domain_hosting',
        component: () => import('../components/Services/DomainHosting.vue'),
        meta: {
          title: 'EPIC portal - Domein & Hosting',
          breadCrumb: { en: 'Domain & Hosting', nl: 'Domein & Hosting' },
          requiresAuth: true,
        },
      },
      {
        path: 'electronic_security',
        name: 'electronic_security',
        component: () => import('../components/Services/ElectronicSecurity.vue'),
        meta: {
          title: 'EPIC portal - Electronic Security',
          breadCrumb: { en: 'Electronic Security', nl: 'Electronische Beveiliging' },
          requiresAuth: true,
        },
      },
      {
        path: 'backup_services',
        name: 'backup_services',
        component: () => import('../components/Services/BackupServices.vue'),
        meta: {
          title: 'EPIC portal - Backup Services',
          breadCrumb: { en: 'Backup Services', nl: 'Backup Diensten' },
          requiresAuth: true,
        },
      },
      {
        path: 'cloud_solutions',
        name: 'cloud_solutions',
        component: () => import('../components/Services/CloudSolutions.vue'),
        meta: {
          title: 'EPIC portal - Cloud Solutions',
          breadCrumb: { en: 'Cloud Solutions', nl: 'Cloud Oplossingen' },
          requiresAuth: true,
        },
      },
      {
        path: 'connectivity',
        name: 'connectivity',
        component: () => import('../components/Services/Connectivity.vue'),
        meta: {
          title: 'EPIC portal - Connectivity',
          breadCrumb: { en: 'Connectivity', nl: 'Verbindingen' },
          requiresAuth: true,
        },
      },
      {
        path: 'email_services',
        name: 'email_services',
        component: () => import('../components/Services/EmailServices.vue'),
        meta: {
          title: 'EPIC portal - Email Services',
          breadCrumb: { en: 'Email Services', nl: 'Email Diensten' },
          requiresAuth: true,
        },
      },
      {
        path: 'managed_it_services',
        name: 'managed_it_services',
        component: () => import('../components/Services/ManagedServices.vue'),
        meta: {
          title: 'EPIC portal - Managed IT Services',
          breadCrumb: { en: 'Managed IT Services', nl: 'Beheerde IT Diensten' },
          requiresAuth: true,
        },
      },
      {
        path: 'security_solutions',
        name: 'security_solutions',
        component: () => import('../components/Services/SecuritySolutions.vue'),
        meta: {
          title: 'EPIC portal - Security solutions',
          breadCrumb: { en: 'Security solutions', nl: 'Elektronische beveiliging' },
          requiresAuth: true,
        },
      },
      {
        path: 'software',
        name: 'software',
        component: () => import('../components/Services/Software.vue'),
        meta: {
          title: 'EPIC portal - Software',
          breadCrumb: { en: 'Software', nl: 'Software' },
          requiresAuth: true,
        },
      },
      {
        path: 'telephony',
        name: 'telephony',
        component: () => import('../components/Services/Telephony.vue'),
        meta: {
          title: 'EPIC portal - Telefonie',
          breadCrumb: { en: 'Telephony', nl: 'Telefonie' },
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/invoices/:filter?',
    name: 'invoices',
    component: () => import('../components/Invoices/Invoices.vue'),
    meta: {
      title: 'EPIC portal - Invoices',
      breadCrumb: { en: 'Invoices', nl: 'Facturen' },
      requiresAuth: true,
    },
  },
  {
    path: '/quotes/:filter?',
    name: 'quotes',
    component: () => import('../components/Quotes/Quotes.vue'),
    meta: {
      title: 'EPIC portal - Quotes',
      breadCrumb: { en: 'Quotes', nl: 'Offertes' },
      requiresAuth: true,
    },
  },
  {
    path: '/quote_preview/:id',
    name: 'quote_preview',
    component: () => import('../components/Quotes/child_components/PreviewQuote.vue'),
    meta: {
      title: 'EPIC portal - Quote Preview',
      requiresAuth: true,
    },
  },
];

export default routes;
