import Vue from "vue";
import VueI18n from "vue-i18n";

import { nl } from "./translations/nl";
import { en } from "./translations/en";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, nl };

var lang = navigator.language || navigator.userLanguage;

if (lang.length > 2) {
	lang = lang.substring(0, 2);
}

// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: lang, // set locale
	messages, // set locale messages
});

export default i18n;
