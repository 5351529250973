<template>
  <div :id="id" class="custom-modal">
    <!-- Modal container -->
    <div class="custom-modal__container" :class="'width-px-' + width">
      <!-- Header section -->
      <div v-if="header" class="custom-modal__container__header">
        <h4 class="custom-modal__container__header__title">{{ title }}</h4>
        <i class="icon--cross" @click="hideModal"></i>
      </div>

      <!-- Content section -->
      <div v-if="$slots.content" class="custom-modal__container__content">
        <slot name="content"></slot>
      </div>

      <!-- Footer section -->
      <div class="custom-modal__container__footer" :class="'flex--justify-' + buttonPosition">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    width: [String, Number],
    buttonPosition: {
      type: String,
      default: 'end',
    },
    header: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },

  mounted() {
    let self = this;

    // Get the button triggering the modal by ID or data attribute
    let button = document.querySelector("[modal-id='" + this.id + "']");
    if (button) {
      button.onclick = function () {
        self.modal.style.display = 'flex';
      };
    }
    // Get all buttons in the footer with a specific class
    let modalButtons = this.modal.getElementsByClassName('modal-button');

    for (let i = 0; i < modalButtons.length; i++) {
      // Add onclick on every button to hide the modal when clicked
      modalButtons[i].onclick = function () {
        self.hideModal();
      };
    }
  },

  methods: {
    // Hide the modal
    hideModal() {
      this.modal.style.display = 'none';
    },
  },

  computed: {
    // Get the modal element by ID
    modal() {
      return document.getElementById(this.id);
    },
  },
};
</script>
