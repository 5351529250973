const actions = {
  resources: {
    action: 'global/resources',
    getter: 'global/resources',
  },
  contacts: {
    action: 'company/contacts',
    getter: 'company/contacts',
  },
  contracts: {
    action: 'company/contracts',
    getter: 'company/contracts',
  },
  subscriptions: {
    action: 'company/subscriptions',
    getter: 'company/subscriptions',
  },
  configurationItems: {
    action: 'company/configurationItems',
    getter: 'company/configurationItems',
  },
  billingCodes: {
    action: 'company/billingCodes',
    getter: 'company/billingCodes',
  },
  companyLocations: {
    action: 'company/companyLocations',
    getter: 'company/companyLocations',
  },
  invoices: {
    action: 'company/invoices',
    getter: 'company/invoices',
  },
  quotes: {
    action: 'company/quotes',
    getter: 'company/quotes',
  },
  tickets: {
    action: 'tickets/tickets',
    getter: 'tickets/tickets',
  },
  completedTickets: {
    action: 'tickets/completedTickets',
    getter: 'tickets/completedTickets',
  },
  projects: {
    action: 'projects/projects',
    getter: 'projects/projects',
  },
};

export default actions;
