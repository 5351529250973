<template>
  <header class="header">
    <div class="header__logo">
      <img
        class="cursor--pointer"
        src="../../assets/images/logo_white.svg"
        alt="EPIC IT Logo"
        @click="navigateToDashboard"
      />
    </div>
    <!-- SEARCH -->
    <Search />
    <div class="header__settings">
      <!-- NOTIFICATIONS -->
      <Notifications />
      <!-- USER SETTINGS -->
      <div class="header__settings__user" @click="toggleDropdown('userSettings')">
        <span class="p--regular cursor--pointer"
          >{{ user.firstName }} {{ user.middleInitial }} {{ user.lastName }}</span
        >
        <i class="icon--drop-down-arrow cursor--pointer"></i>
        <div class="header__settings__user__dropdown" v-show="dropdown.userSettings">
          <div class="header__settings__user__dropdown__account-info">
            <span class="width-100 padding-5 padding--bottom-0 p--regular text--line-height-20"
              >{{ user.firstName }} {{ user.middleInitial }} {{ user.lastName }}
            </span>
            <span class="width-100 padding-5 padding--top-0 p--small"
              >{{ user.emailAddress }}
            </span>
          </div>
          <div class="header__settings__user__dropdown__account-actions">
            <span class="padding-5 p--regular text--line-height-20 cursor--pointer" @click="logout">
              Log uit
            </span>
          </div>
        </div>
      </div>
      <!-- LANGUAGE SWITCHER -->
      <div class="header__settings__language cursor--pointer" @click="toggleDropdown('language')">
        <img :src="getFlag(language)" :alt="language" />
        <div class="header__settings__language__dropdown" v-show="dropdown.language">
          <div
            class="header__settings__language__dropdown__list"
            @click="setLocale(language)"
            v-for="language in languages"
            :key="language"
          >
            <div class="header__settings__language__dropdown__list__item">
              <span class="p--light width-px-16">{{ language.toUpperCase() }}</span>
              <img :src="getFlag(language)" :alt="language" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Search from './Header/Search.vue';
import Notifications from './Header/Notifications.vue';
export default {
  components: { Search, Notifications },
  data() {
    return {
      languages: ['nl', 'en'],
      language: this.$i18n.locale,
    };
  },

  created() {
    let storedLanguage = localStorage.getItem('language');

    if (storedLanguage) {
      this.setLocale(this.languages.find((lang) => lang === storedLanguage));
    } else {
      this.setLocale('nl');
    }
  },

  methods: {
    navigateToDashboard() {
      if (this.$route.name != 'dashboard') {
        this.$router.push({ name: 'dashboard' });
      }
    },

    logout() {
      this.$store.commit('login/logout', 'U bent afgemeld');
    },

    toggleDropdown(type) {
      this.$store.commit('global/headerDropdown', type);
    },

    getFlag(language) {
      var images = require.context('../../assets/images/country_flags/', false, /\.png$/);
      return images('./' + language + '.png');
    },

    setLocale(lang) {
      this.$i18n.locale = lang;

      localStorage.setItem('language', lang);

      this.$http.defaults.headers.common['Accept-Language'] = lang;

      if (this.$table) {
        this.$table.setLocale(lang);
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters['login/authUser'];
    },

    dropdown() {
      return this.$store.getters['global/headerDropdown'];
    },
  },
};
</script>
