<template>
  <div class="select" :class="classHandler" :disabled="disabled">
    <div class="select__button" @click="toggleMenu()">
      <!-- Selected option without icon -->
      <span v-if="type != 'icon'" class="p--regular text--truncate max-width-100">{{
        selected.title
      }}</span>
      <!-- Selected option with icon -->
      <span class="flex flex--items-center gap-12" v-else>
        <i :class="selected.icon ? selected.icon : 'display--none'"></i>
        <span :class="selected.class + ' text--truncate max-width-100 padding--bottom-3'">{{
          selected.title
        }}</span>
      </span>
      <i class="icon--select"></i>
    </div>

    <ul v-if="type == 'default'" :class="popupHandler">
      <li
        v-for="option in options"
        :key="option.value"
        class="select__options__option p--regular"
        @click="selectOption(option.text, option.value)"
      >
        <span :class="option.textClass + ' text--truncate max-width-100'">{{ option.text }}</span>
      </li>
    </ul>
    <ul v-else-if="type == 'icon'" :class="popupHandler">
      <li
        v-for="option in options"
        :key="option.value"
        class="select__options__option p--regular"
        @click="selectOption(option.text, option.value, option)"
      >
        <i :class="option.iconClass"></i>
        <span :class="option.textClass + ' text--truncate max-width-100 p--regular'">{{
          option.text
        }}</span>
      </li>
    </ul>
    <ul v-else-if="type == 'users'" :class="popupHandler">
      <li
        v-for="option in options"
        :key="option.id"
        class="select__options__option p--regular gap-10 flex"
        @click="selectOption(option.name, option.id)"
      >
        <span class="border-rd-25 height-px-18 width-px-18" :class="'bg--' + option.color"></span>
        <span :class="'text-' + option.color + ' text--truncate max-width-100'">{{
          option.name
        }}</span>
      </li>
    </ul>
    <ul v-else-if="type == 'searchable'" :class="popupHandler">
      <input
        type="text"
        v-model="searchQuery"
        class="select__options__input input--focus-no p--regular"
        placeholder="Search..."
      />
      <div class="overflow max-height-px-200">
        <li
          v-for="option in filteredOptions"
          :key="option.value"
          class="select__options__option text-white-200 p--regular"
          @click="selectOption(option.name, option.id)"
        >
          <span class="text-white-100 p--regular text--truncate max-width-100">{{
            option.name
          }}</span>
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    title: String,
    activeTitle: {
      type: String,
      default: '',
    },
    options: Array,
    width: [String, Number],
    popup: {
      default: false,
      type: Boolean,
    },
    variant: {
      validator: function (value) {
        // The value must match one of these strings
        return ['dark-100', 'dark-200'].includes(value);
      },
    },
    type: {
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['default', 'icon', 'users', 'searchable'].includes(value);
      },
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    clearable: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      selected: {
        title: '',
        icon: '',
        class: '',
      },
      searchQuery: '',
    };
  },

  mounted() {
    this.selected.title = this.title;
    this.preselectProject();

    if (this.value !== null) {
      // Find the option that matches the initial value
      let initialOption = this.options.find(
        (option) => option.value == this.value || option.id === this.value
      );

      if (initialOption != undefined) {
        if (this.type == 'default' || this.type == 'icon') {
          this.selected.title = initialOption.text;
          if (this.type == 'icon') {
            this.selected.icon = initialOption.iconClass;
            this.selected.class = initialOption.textClass;
          }
        } else if (this.type == 'users' || this.type == 'searchable') {
          this.selected.title = initialOption.name;
        }
      }
    }

    let selects = document.querySelectorAll('.select');

    selects.forEach((select) => {
      select.id = this._uid;
    });

    // Prepend 'Geen' option to options array
    this.clearableHandler();
  },

  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;

      if (this.menuActive) {
        let activeMenus = document.querySelectorAll('.select--active');

        activeMenus.forEach((menu) => {
          menu.classList.remove('select--active');

          this.$store.commit('global/activeSelect', { id: menu.id, value: false });
        });
      }

      if (this.activeTitle.length) {
        this.menuActive
          ? (this.selected.title = this.activeTitle)
          : (this.selected.title = this.title);
      }
    },

    selectOption(name, value, option) {
      if (this.type == 'icon') {
        this.selected.icon = option.iconClass;
        this.selected.class = option.textClass;
      }
      this.selected.title = name;
      this.menuActive = false;

      this.$emit('input', value);
    },

    preselectProject() {
      // Assuming options is an array of objects with 'id' and 'name'
      if (this.$route.params.id) {
        let selectedOption = this.options.find((option) => option.id === this.$route.params.id);
        if (selectedOption) {
          this.selectOption(selectedOption.name, selectedOption.id);
        }
      }
    },

    clearableHandler() {
      if (this.clearable === true) {
        // Check if the 'Geen' option already exists
        const clearableExists = this.options.some(
          (option) => (option.text === 'Geen') | (option.name === 'Geen')
        );

        // Only add the 'Geen' option if it does not already exist
        if (!clearableExists) {
          this.options.unshift({
            text: 'Geen',
            value: null,
            textClass: '',
            name: 'Geen',
            id: null,
          });
        }
      } else {
        return;
      }
    },
  },

  computed: {
    classHandler() {
      if (this.menuActive) {
        return 'select--active ' + 'select--variant-' + this.variant + ' width-px-' + this.width;
      } else {
        return 'select--variant-' + this.variant + ' width-px-' + this.width;
      }
    },
    popupHandler() {
      if (this.popup === true) {
        return ' select__options' + ' select__options--popup';
      } else {
        return ' select__options';
      }
    },

    filteredOptions() {
      if (this.searchQuery) {
        return this.options.filter((option) =>
          option.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return this.options;
    },

    menuActive: {
      get: function () {
        return this.$store.getters['global/activeSelects'](this._uid);
      },

      set: function (newValue) {
        this.$store.commit('global/activeSelect', { id: this._uid, value: newValue });
      },
    },
  },
};
</script>
