<template>
  <div class="notifications">
    <div class="notifications__button cursor--pointer" @click="toggleDropdown('notifications')">
      <span v-if="notifications.length">{{ notifications.length }}</span>
      <i v-if="notifications.length" class="icon--filled-notifications"></i>
      <i v-else class="icon--empty-notifications"></i>
    </div>

    <div class="notifications__dropdown" v-show="dropdown.notifications">
      <!-- NOTIFICATIONS HEADER -->
      <div class="notifications__dropdown__header">
        <span class="p--regular">Notificaties</span>
        <i class="icon--trash epic-tooltip" @click="closeNotifications">
          <span class="epic-tooltip__text shadow">Verwijder alle berichten</span>
        </i>
      </div>
      <div class="notifications__dropdown__list overflow--scroll overflow--variant-notifications">
        <!-- NOTIFICATIONS PLACEHOLDER -->
        <span
          v-if="!notifications.length"
          class="notifications__dropdown__list__placeholder p--small"
        >
          Uw notificaties verschijnen hier
        </span>
        <!-- NOTIFICATIONS LIST -->
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="notifications__dropdown__list__item"
        >
          <div
            class="notifications__dropdown__list__item__content"
            v-if="notification.category != 'sticky'"
          >
            <div class="notifications__dropdown__list__item__content__description">
              <i v-if="notification.category == 'success'" class="icon--success-notification"></i>
              <i v-else class="icon--info-notification"></i>
              <span :id="notification.id">{{ notification.description }}</span>
            </div>
            <div class="notifications__dropdown__list__item__content__cross">
              <i class="icon--cross-notification" @click="closeNotification(notification.id)"> </i>
            </div>
          </div>
          <!-- STICKY NOTIFICATION -->
          <div
            class="notifications__dropdown__list__item__content--sticky"
            v-else-if="notification.category == 'sticky'"
            @click="navigationHandler(notification)"
          >
            <div class="notifications__dropdown__list__item__content__title">
              <p class="p--regular">{{ notification.title }}</p>
              <i class="icon--circle-notification"> </i>
            </div>
            <div class="notifications__dropdown__list__item__content__description">
              <span :id="notification.id">{{ notification.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      intervalId: null,
      initialLoad: true,
    };
  },

  // mounted() {
  //   // Retrieve the notifications every 5 minutes
  //   this.intervalId = setInterval(() => {
  //     let previousNotificationAmount = this.notifications.length;

  //     this.$store.dispatch('global/notifications', this.user.id).then((notifications) => {
  //       if (
  //         notifications.length &&
  //         notifications.length > previousNotificationAmount &&
  //         !document.hasFocus()
  //       ) {
  //         if (notifications.length == 1) {
  //           document.title = '1 nieuwe melding';
  //         } else {
  //           document.title = notifications.length + ' nieuwe meldingen';
  //         }
  //       } else {
  //         document.title = this.$route.meta.title;
  //       }
  //     });
  //   }, 300000);
  // },

  mounted() {
    this.intervalId = setInterval(() => {
      this.notificationHandler();

      let previousNotificationAmount = this.notifications.length;

      this.$store.dispatch('global/notifications', this.user.id).then((notifications) => {
        if (
          notifications.length &&
          notifications.length > previousNotificationAmount &&
          !document.hasFocus()
        ) {
          if (notifications.length == 1) {
            document.title = '1 nieuwe melding';
          } else {
            document.title = notifications.length + ' nieuwe meldingen';
          }
        } else {
          document.title = this.$route.meta.title;
        }
      });
    }, 60000);
  },

  watch: {
    dataLoaded: function () {
      if (this.initialLoad) {
        this.notificationHandler();

        this.initialLoad = false;
      }
    },
  },

  methods: {
    toggleDropdown(type) {
      this.$store.commit('global/headerDropdown', type);
    },

    notificationHandler() {
      if (this.isLoggedIn) {
        const contact = this.contacts.find(
          (contact) => contact.emailAddress == this.user.emailAddress
        );

        if (contact != undefined) {
          this.$store.dispatch('tickets/tickets', this.user.company_id).then((tickets) => {
            // Filter through the tickets with status "waiting customer".
            const waitingCustomer = tickets.filter(
              (ticket) => ticket.status == 7 && contact.id == ticket.contact_id
            );

            if (waitingCustomer.length) {
              this.addNotifications('tickets', tickets);
            }
          });

          this.$store.dispatch('projects/projects', this.user.company_id).then(() => {
            // Filter through the tasks of projects to find tasks with status "waiting customer".
            const waitingCustomer = this.tasks.filter((task) => task.status == 7);

            if (waitingCustomer.length) {
              this.addNotifications('projects', this.tasks);
            }
          });

          this.$store.dispatch('company/quotes', this.user.company_id).then((quotes) => {
            // Filter through the quotes finding ones with status "Waiting approval" and not accepted or rejected.
            const actionNeeded = quotes.filter(
              (quote) =>
                quote.status == 2 &&
                (!quote.accepted || quote.status != 4) &&
                quote.contact_id == contact.id
            );

            if (actionNeeded.length) {
              this.addNotifications('quotes', actionNeeded);
            }
          });

          this.$store.dispatch('company/invoices', this.user.company_id).then((invoices) => {
            // Filter through the invoices finding the ones that have passed their pay due date.
            const invoicesOverDue = invoices.filter((invoice) => {
              const dueDate = new Date(invoice.dueDate);
              const currentDate = new Date();

              if (dueDate < currentDate && invoice.paidDate == null && !invoice.processing) {
                return true;
              }
            });

            if (invoicesOverDue.length) {
              this.addNotifications('invoicesOverDue', [invoicesOverDue[0]]);
            }

            const paidInvoices = invoices.filter((invoice) => {
              if (invoice.paidDate != null && !invoice.processing) {
                return true;
              }
            });

            if (paidInvoices.length) {
              this.addNotifications('paidInvoices', paidInvoices);
            }
          });
        }
      }
    },

    addNotifications(type, data) {
      let notification = {};
      let existingNotification = null;

      data.forEach((item) => {
        switch (type) {
          case 'tickets':
            // Prevent duplicate notifications by checking if notification already exists.
            existingNotification = this.notifications.find(
              (notification) => notification.item_id == item.id && !notification.opened
            );

            if (existingNotification == undefined && item.status == 7) {
              // When a ticket status is "waiting customer" create notification with a call for action.
              notification = {
                user_id: this.user.id,
                item_id: item.id,
                item_type: 'ticket',
                category: 'sticky',
                title: 'Actie vereist',
                description: `Actie vereist op ticket ${item.ticketNumber}.`,
              };

              this.$store.dispatch('global/notification', notification).then(() => {
                this.$store.dispatch('global/notifications', this.user.id);
              });
            } else if (existingNotification != undefined && item.status != 7) {
              // Extra checkup to close the notification for when action is successfully done in ticket.
              this.$store.dispatch('global/closeNotification', existingNotification.id);
            }
            break;
          case 'projects':
            const project = this.projects.find((project) => item.project_id == project.id);

            // Prevent duplicate notifications by checking if notification already exists.
            existingNotification = this.notifications.find(
              (notification) => notification.item_id == project.id && !notification.opened
            );

            if (existingNotification == undefined && item.status == 7) {
              // When a task of a project is on "waiting customer" create notification with a call for action.
              notification = {
                user_id: this.user.id,
                item_id: project.id,
                item_type: 'project',
                category: 'sticky',
                title: 'Actie vereist',
                description: `Actie vereist op project ${project.projectNumber}.`,
              };

              this.$store.dispatch('global/notification', notification).then(() => {
                this.$store.dispatch('global/notifications', this.user.id);
              });
            }
            break;
          case 'quotes':
            // Prevent duplicate notifications by checking if notification already exists.
            existingNotification = this.notifications.find(
              (notification) => notification.item_id == item.id && !notification.opened
            );

            if (existingNotification == undefined) {
              notification = {
                user_id: this.user.id,
                item_id: item.id,
                item_type: 'quote',
                category: 'sticky',
                title: 'Actie vereist',
                description: `Actie vereist op offerte ${item.number}.`,
              };

              this.$store.dispatch('global/notification', notification).then(() => {
                this.$store.dispatch('global/notifications', this.user.id);
              });
            }
            break;
          case 'invoicesOverDue':
            // Prevent duplicate notifications by checking if notification already exists.
            existingNotification = this.notifications.find(
              (notification) =>
                notification.item_type == 'invoice' &&
                notification.category == 'sticky' &&
                !notification.opened
            );

            if (existingNotification == undefined) {
              // Notification for when single or multiple invoices are over their due date.
              notification = {
                user_id: this.user.id,
                item_id: 0,
                item_type: 'invoice',
                category: 'sticky',
                title: 'Let op!',
                description: 'U heeft verlopen facturen open staan',
              };

              this.$store.dispatch('global/notification', notification).then(() => {
                const infoNotifications = this.notifications.filter(
                  (notification) =>
                    notification.item_type == 'invoice' &&
                    notification.category == 'info' &&
                    !notification.opened
                );
                // Close all informative notifications that belong to invoices.
                infoNotifications.forEach((notification, i) => {
                  this.$store.dispatch('global/closeNotification', notification.id);

                  if (infoNotifications.length == i) {
                    this.$store.dispatch('global/notifications', this.user.id);
                  }
                });
              });
            }
            break;
          case 'paidInvoices':
            // Prevent duplicate notifications by checking if notification already exists.
            existingNotification = this.allNotifications.find(
              (notification) =>
                notification.item_id == item.id &&
                notification.item_type == 'invoice' &&
                notification.category == 'success'
            );

            if (existingNotification == undefined) {
              notification = {
                user_id: this.user.id,
                item_id: item.id,
                item_type: 'invoice',
                category: 'success',
                description: 'Uw betaling is succesvol verwerkt',
              };

              this.$store.dispatch('global/notification', notification).then(() => {
                this.$store.dispatch('global/notifications', this.user.id);
              });
            }
            break;
          default:
            break;
        }
      });
    },

    // Close notification on ID.
    closeNotification(id) {
      const index = this.notifications.findIndex((notification) => notification.id == id);

      this.notifications.splice(index, 1);

      this.$store.dispatch('global/closeNotification', id);
    },

    // Close all notification that are not sticky.
    closeNotifications() {
      let notificationsIds = this.notifications.map((notification) => {
        if (notification.category != 'sticky') {
          return notification.id;
        }
      });

      const remainingNotifications = this.notifications.filter(
        (notification) => notification.category == 'sticky'
      );

      this.$store.commit('global/notifications', remainingNotifications);

      this.$store.dispatch('global/closeNotification', notificationsIds);
    },

    navigationHandler(notification) {
      switch (notification.item_type) {
        case 'ticket':
          const ticket = this.tickets.find((ticket) => ticket.id == notification.item_id);
          this.$router.push({ name: 'ticket_details', params: { id: ticket.ticketNumber } });
          break;
        case 'project':
          this.$router.push({ name: 'project_details', params: { id: notification.item_id } });
          break;
        case 'quote':
          const quote = this.quotes.find((quote) => quote.id == notification.item_id);

          this.$store.commit('company/setQuote', quote);
          let route = this.$router.resolve({
            name: 'quote_preview',
            params: { id: quote.number },
          });
          this.$store
            .dispatch('company/quoteItems', quote.id)
            .then(window.open(route.href, '_blank'));
          break;
        case 'invoice':
          this.$router.push({ name: 'invoices' });
          break;
        default:
          break;
      }

      this.toggleDropdown('notifications');
    },
  },

  computed: {
    dataLoaded: function () {
      return this.$store.getters['global/dataLoaded'];
    },

    isLoggedIn: function () {
      return this.$store.getters['login/isLoggedIn'];
    },

    user() {
      return this.$store.getters['login/authUser'];
    },

    contacts() {
      return this.$store.getters['company/contacts'];
    },

    tickets() {
      return this.$store.getters['tickets/tickets'];
    },

    projects() {
      return this.$store.getters['projects/projects'];
    },

    tasks() {
      return this.$store.getters['projects/tasks'];
    },

    quotes() {
      return this.$store.getters['company/quotes'];
    },

    dropdown() {
      return this.$store.getters['global/headerDropdown'];
    },

    // Open notifications
    notifications() {
      let notifications = this.$store.getters['global/notifications'];

      // Sort notifications so sticky notification are shown first and after that newly created at the bottom.
      notifications.sort((a, b) => {
        // Compare category: "sticky" should come first
        if (a.category === 'sticky' && b.category !== 'sticky') {
          return -1;
        } else if (a.category !== 'sticky' && b.category === 'sticky') {
          return 1;
        }

        const aDate = new Date(a.created_at);
        const bDate = new Date(b.created_at);

        // If both have the same category, compare created_at date
        return bDate - aDate;
      });

      return notifications;
    },

    // Open and closed notifications.
    allNotifications() {
      return this.$store.getters['global/allNotifications'];
    },
  },
};
</script>

<style></style>
