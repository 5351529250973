import axios from 'axios';

const state = {
  widget: {
    type: '',
  },
  newWidget: {
    id: null,
    type: '',
    title: '',
    w: 4,
    h: 33,
    minW: 3,
    minH: 33,
    content: null,
    visible: false,
    items: [],
  },
  widgetContent: ``,
  widgetInnerContent: ``,

  services: [
    { name: 'Domein & Hosting', value: 'domainHosting' },
    { name: 'Electronische Beveiliging', value: 'electronicSecurity' },
    { name: 'Backup diensten', value: 'backupServices' },
    { name: 'Cloud oplossingen', value: 'cloudSolutions' },
    { name: 'Verbindingen', value: 'connectivity' },
    { name: 'Email Diensten', value: 'emailServices' },
    { name: 'Beheerde IT Diensten', value: 'managedItServices' },
    { name: 'Beveiligingsoplossingen', value: 'securitySolutions' },
    { name: 'Software', value: 'software' },
    { name: 'Telefonie', value: 'telephony' },
  ],

  dataSynced: false,
};

const getters = {
  widget: (state) => state.widget,
  newWidget: (state) => state.newWidget,
  services: (state) => state.services,

  dataSynced: (state) => state.dataSynced,
};

const actions = {
  updateWidgetLayout(context, data) {
    axios
      .patch(context.rootState.domains.users_api + `user/widget_layout`, {
        id: data[0],
        widgets: data[1],
      })
      .then((response) => {});
  },
};

const mutations = {
  addWidget(state, data) {
    state.newWidget.id = data[0];
    state.newWidget.type = data[1];
    state.newWidget.title = data[2];

    const id = data[0];
    let widgetType = data[3];

    // Set widget content based on the selected widget.
    switch (state.newWidget.type) {
      case 'services':
        state.newWidget.h = 32;
        state.newWidget.w = 3;
        state.widgetInnerContent = `
				<div class="chart-container"><canvas id="${widgetType}_${id}" name="${state.newWidget.title}"> </canvas></div>
				`;
        break;
      case 'tickets':
        widgetType = data[1];
        state.newWidget.visible = true;
        state.newWidget.items = [
          {
            id: `open_tickets_table_${id}`,
            type: 'table',
            visible: true,
          },
          { id: `open_tickets_circle_${id}`, type: 'circle', visible: false },
          { id: `open_tickets_pie_${id}`, type: 'pie', visible: false },
          { id: `open_tickets_bar_${id}`, type: 'bar', visible: false },
        ];

        state.widgetInnerContent = `
				<div id="open_tickets_table_${id}" class="table-wrapper"><div id="open_tickets_${id}" class="table-container"></div></div>
				<div class="chart-container circle"><canvas id="open_tickets_circle_${id}" name="Open Tickets"> </canvas></div>
				<div class="chart-container pie"><canvas id="open_tickets_pie_${id}" name="Open Tickets"> </canvas></div><div id="legend-container-${id}" class="legend-container"></div>
				<div class="chart-container bar"><canvas id="open_tickets_bar_${id}" name="Open Tickets"> </canvas></div>`;

        break;
      case 'news_feed':
        state.widgetInnerContent = `News Feed`;
        break;

      default:
        break;
    }
    // Setup widget content
    this.commit('widgets/widgetContent', widgetType);
  },

  widgetContent(state, type) {
    state.newWidget.content = `
		<div id="${state.newWidget.id}" class="container-fluid widget-container">
			<div class="row widget-header">
				<div id="${type}" class="col widget-title">${state.newWidget.title}</div>
				<div class="col widget-settings">
					<div class="dropdown">
						<i class="fa-solid fa-sliders dropdown-toggle pointer" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"></i>
						<ul class="dropdown-menu settings">
							<li><a class="dropdown-item widget-settings-toggle" href="#"><i class="fa-sharp fa-solid fa-gear pointer"></i> Instellingen</a></li>
							<li><a class="dropdown-item remove-widget" href="#"><i class="fa-regular fa-trash text-danger"></i> Delete</a></li>
						</ul>			
					</div>
				</div>
			</div>
			<div class="widget-content ${state.newWidget.type}">
				${state.widgetInnerContent}
			</div>
		</div>`;
  },

  setWidget(state, data) {
    state.widget = data;
  },

  resetWidget(state) {
    state.newWidget = {
      id: null,
      type: '',
      title: '',
      w: 4,
      h: 33,
      minW: 3,
      minH: 33,
      content: null,
      items: [],
    };
    state.widgetContent = ``;
    state.widgetInnerContent = ``;
  },

  dataSynced(state) {
    state.dataSynced = !state.dataSynced;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
