<template>
  <div>
    <div
      class="flex flex--items-center gap-13 padding-y-2 padding-x-8 bg--dark-200 border-rd-8"
      v-if="type == 'default'"
    >
      <div class="flex flex--items-start">
        <div>{{ formattedTime }}</div>
      </div>
      <div class="border-separator"></div>
      <div class="flex flex--items-center gap-10">
        <button
          class="border-0 bg--dark-200 icon--play"
          @click="start"
          v-if="!timerTrackerActive"
        ></button>
        <button class="border-0 bg--dark-200 icon--pause" @click="pause" v-else></button>
        <button class="border-0 bg--dark-200 icon--record" modal-id="timeEntry"></button>
        <button class="border-0 bg--dark-200 icon--reset" @click="reset"></button>
      </div>

      <modal :id="'timeEntry'" :title="'Tijdinvoer'" :width="615" :buttonPosition="'between'">
        <template v-slot:content>
          <textarea
            class="textarea textarea--focus-no bg--dark-200 width-100 flex padding-x-10 padding-y-4 height-px-252"
            placeholder="Vul in . . ."
            v-model="description"
          ></textarea>
        </template>
        <template v-slot:footer>
          <div
            class="flex flex--items-center gap-13 padding-y-2 padding-x-8 bg--dark-200 border-rd-8"
          >
            <div class="flex flex--justify-between">
              <input
                type="number"
                @input="validateInput('hours', $event)"
                class="input__number border-0 bg--dark-200 width-px-20 flex"
                min="00"
                max="23"
                v-model="formattedHours"
                placeholder="HH"
                @focus="pause"
                maxlength="2"
              />:
              <input
                type="number"
                @input="validateInput('minutes', $event)"
                class="input__number border-0 bg--dark-200 width-px-20"
                min="00"
                max="59"
                v-model="formattedMinutes"
                placeholder="MM"
                @focus="pause"
                maxlength="2"
              />:
              <input
                type="number"
                @input="validateInput('seconds', $event)"
                class="input__number border-0 bg--dark-200 width-px-20"
                min="00"
                max="59"
                v-model="formattedSeconds"
                placeholder="SS"
                @focus="pause"
                maxlength="2"
              />
            </div>
            <div class="border-separator"></div>
            <div class="flex flex--items-center gap-10">
              <button
                class="border-0 bg--dark-200 icon--play"
                @click="start"
                v-if="!timerTrackerActive"
              ></button>
              <button class="border-0 bg--dark-200 icon--pause" @click="pause" v-else></button>

              <button class="border-0 bg--dark-200 icon--reset" @click="reset"></button>
            </div>
          </div>
          <div class="flex gap-10">
            <button class="button button--color-green modal-button" @click="addTimeEntryHandeler">
              Accepteer
            </button>
            <button class="button button--color-red modal-button">Annuleer</button>
          </div>
        </template>
      </modal>
    </div>
    <div
      class="flex flex--items-center gap-13 padding-y-2 padding-x-8 bg--dark-200 border-rd-8"
      v-else-if="type == 'small'"
    >
      <div class="flex flex--justify-between" v-if="hoursLimitations === true">
        <input
          type="number"
          @input="validateInput('hours', $event)"
          class="input__number border-0 bg--dark-200 width-px-20 flex"
          min="00"
          max="23"
          v-model="formattedHours"
          placeholder="HH"
          @focus="pause"
          maxlength="2"
        />:
        <input
          type="number"
          @input="validateInput('minutes', $event)"
          class="input__number border-0 bg--dark-200 width-px-20"
          min="00"
          max="59"
          v-model="formattedMinutes"
          placeholder="MM"
          @focus="pause"
          maxlength="2"
        />:
        <input
          type="number"
          @input="validateInput('seconds', $event)"
          class="input__number border-0 bg--dark-200 width-px-20"
          min="00"
          max="59"
          v-model="formattedSeconds"
          placeholder="SS"
          @focus="pause"
          maxlength="2"
        />
      </div>
      <div class="flex flex--justify-between" v-if="hoursLimitations === false">
        <input
          type="number"
          @input="validateInput('hours', $event)"
          class="input__number border-0 bg--dark-200 width-px-20 flex"
          min="00"
          v-model="formattedHours"
          placeholder="HH"
          @focus="pause"
          maxlength="2"
        />:
        <input
          type="number"
          @input="validateInput('minutes', $event)"
          class="input__number border-0 bg--dark-200 width-px-20"
          min="00"
          max="59"
          v-model="formattedMinutes"
          placeholder="MM"
          @focus="pause"
          maxlength="2"
        />:
        <input
          type="number"
          @input="validateInput('seconds', $event)"
          class="input__number border-0 bg--dark-200 width-px-20"
          min="00"
          max="59"
          v-model="formattedSeconds"
          placeholder="SS"
          @focus="pause"
          maxlength="2"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      description: '',
      hours: 0,
      minutes: 0,
      seconds: 0,
      recordedTime: '',
      intervalId: null,
      timerTrackerActive: false,
    };
  },
  props: {
    type: {
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['default', 'small'].includes(value);
      },
    },
    hoursLimitations: {
      default: true,
      type: Boolean,
    },
    autostart: {
      default: true,
      type: Boolean,
    },
    addTimeEntry: Function,
  },

  mounted() {
    if (this.autostart == true) {
      this.start();
    }
  },

  methods: {
    start() {
      if (this.intervalId) return;
      this.intervalId = setInterval(() => {
        this.updateTime();
      }, 1000);
    },

    pause() {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.timerTrackerActive = false;
    },

    recordTime() {
      this.recordedTime = this.formattedTime;
    },

    reset() {
      this.pause();
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
    },

    updateTime() {
      if (this.hours == 23 && this.minutes == 59 && this.seconds == 59) {
        this.pause();
      } else {
        this.timerTrackerActive = true;
        this.seconds++;

        if (this.seconds >= 60) {
          this.seconds = 0;
          this.minutes++;
        }
        if (this.minutes >= 60) {
          this.minutes = 0;
          this.hours++;
        }
      }
    },

    addTimeEntryHandeler() {
      this.addTimeEntry(this.description, this.hours, this.minutes, this.seconds);
      this.description = '';
    },

    validateInput(field, event) {
      let value = parseInt(event.target.value);
      if (isNaN(value)) {
        value = 0;
      }
      if (field === 'hours' && this.hoursLimitations === true) {
        this.hours = value > 23 ? 23 : value;
      } else if (field === 'minutes' || field === 'seconds') {
        if (value > 59) {
          value = 59;
        }
        if (field === 'minutes') {
          this.minutes = value;
        } else {
          this.seconds = value;
        }
      }

      let hoursFormatted = String(this.hours).padStart(2, '0');
      let minutesFormatted = String(this.minutes).padStart(2, '0');
      let secondsFormatted = String(this.seconds).padStart(2, '0');

      var time = hoursFormatted + ':' + minutesFormatted + ':' + secondsFormatted;
      this.$emit('selectedTime', time);
    },
  },

  computed: {
    formattedTime() {
      return `${this.hours.toString().padStart(2, '0')}:${this.minutes
        .toString()
        .padStart(2, '0')}:${this.seconds.toString().padStart(2, '0')}`;
    },

    formattedHours: {
      get() {
        return this.hours.toString().padStart(2, '0');
      },
      set(value) {
        this.hours = parseInt(value) || 0;
      },
    },

    formattedMinutes: {
      get() {
        return this.minutes.toString().padStart(2, '0');
      },
      set(value) {
        this.minutes = parseInt(value) || 0;
      },
    },

    formattedSeconds: {
      get() {
        return this.seconds.toString().padStart(2, '0');
      },
      set(value) {
        this.seconds = parseInt(value) || 0;
      },
    },
  },
};
</script>
