import axios from 'axios';

const state = {
  subscriptions: [],
  configurationItems: [],

  contracts: [],
  contract: [],

  contacts: [],
  companyLocations: [],
  invoice: [],

  invoices: [],
  quotes: [],
  quoteItems: [],
  quote: {},
  billingCodes: [],

  // SERVICES
  services: [],

  serviceCategories: [
    { service: 'domainHosting', name: 'Domein & Hosting', contractCategory: 23 },
    {
      service: 'electronicSecurity',
      name: 'Electronische beveiliging',
      contractCategory: 21,
    },
    { service: 'emailServices', name: 'Email diensten', contractCategory: 14 },
    { service: 'cloudSolutions', name: 'Cloud oplossingen', contractCategory: 19 },
    { service: 'connectivity', name: 'Verbindingen', contractCategory: 17 },
    {
      service: 'managedServices',
      name: 'Beheerde IT Diensten',
      contractCategory: [11, 12, 13, 20],
    },
    {
      service: 'securitySolutions',
      name: 'Beveiligingsoplossingen',
      contractCategory: 22,
    },
    { service: 'telephony', name: 'Telefonie', contractCategory: 18 },
    {
      service: 'software',
      contractCategory: undefined,
    },
  ],

  domainHosting: [],
  electronicSecurity: [],
  backupServices: [],
  cloudSolutions: [],
  connectivity: [],
  emailServices: [],
  managedServices: [],
  securitySolutions: [],
  telephony: [],
  software: [],
};

const getters = {
  contract: (state) => state.contract,
  contracts: (state) => state.contracts,

  contacts: (state) => state.contacts,
  companyLocations: (state) => state.companyLocations,
  invoice: (state) => state.invoice,

  subscriptions: (state) => state.subscriptions,
  configurationItems: (state) => state.configurationItems,
  invoices: (state) => state.invoices,
  quotes: (state) => state.quotes,
  quoteItems: (state) => state.quoteItems,
  quote: (state) => state.quote,
  billingCodes: (state) => state.billingCodes,

  services: (state) => state.services,

  domainHosting: (state) => state.domainHosting,
  electronicSecurity: (state) => state.electronicSecurity,
  backupServices: (state) => state.backupServices,
  cloudSolutions: (state) => state.cloudSolutions,
  connectivity: (state) => state.connectivity,
  emailServices: (state) => state.emailServices,
  managedServices: (state) => state.managedServices,
  securitySolutions: (state) => state.securitySolutions,
  telephony: (state) => state.telephony,
  software: (state) => state.software,
};

const actions = {
  configurationItems(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `configuration_items/${id}`)
        .then((response) => {
          context.commit('configurationItems', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  subscriptions(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `subscriptions/${id}`)
        .then((response) => {
          context.commit('subscriptions', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  contracts(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `contracts/${id}`)
        .then((response) => {
          context.commit('services', []);
          context.commit('contracts', response.data);
          if (response.data.length) {
            state.serviceCategories.forEach((service) => {
              context.commit('setService', service);
            });
          }
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  contacts(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `contacts/${id}`)
        .then((response) => {
          context.commit('contacts', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  companyLocations(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `company_locations/${id}`)
        .then((response) => {
          context.commit('companyLocations', response.data[0]);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  invoices(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `invoices/${id}`)
        .then((response) => {
          context.commit('invoices', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  downloadInvoice(context, data) {
    axios
      .get(context.rootState.domains.external_api + `invoices/${data.id}/download`, {
        responseType: 'blob',
      })
      .then((response) => {
        // Default filename in case the header is missing or doesn't match
        let filename = data.number + '.pdf';

        // Create a blob object from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' });

        // Create a temporary URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);

        // Create an anchor element for downloading
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename; // Specify the filename
        a.style.display = 'none';

        // Append the anchor to the document and trigger the click event
        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the blob URL
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        // Handle error if necessary
      });
  },

  processInvoice(context, ids) {
    return new Promise((resolve, reject) => {
      axios
        .patch(context.rootState.domains.external_api + `invoices/processing`, { ids: ids })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error);
        });
    });
  },

  quotes(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `quotes/${id}`)
        .then((response) => {
          context.commit('quotes', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  quoteItems(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `quotes/${id}/items`)
        .then((response) => {
          context.commit('quoteItems', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  acceptQuote(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(context.rootState.domains.external_api + `quotes/accept`, data)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error);
        });
    });
  },

  rejectQuote(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(context.rootState.domains.external_api + `quotes/reject`, {
          id: id,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error);
        });
    });
  },

  billingCodes(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `billing_codes`)
        .then((response) => {
          context.commit('billingCodes', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  newRequest(context, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      // Ensure payload.attachments is an array and append each file to formData
      if (Array.isArray(data.attachments)) {
        data.attachments.forEach((file, index) => {
          formData.append(`attachments[${index}]`, file);
        });
      }

      // Append other form data from the payload
      formData.append('title', data.title);
      formData.append('description', data.description);
      formData.append('ticketType', data.ticketType);
      formData.append('company_id', data.company_id);
      formData.append('contact_id', data.contact_id);
      formData.append('QueueID', data.queue_id);
      formData.append('status', data.status);
      formData.append('priority', data.priority);

      axios
        .post(context.rootState.domains.external_api + `new_request`, formData)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error);
        });
    });
  },
};

const mutations = {
  companyExists(state, data) {
    return (state.companyExists = data);
  },

  configurationItems(state, data) {
    return (state.configurationItems = data.length ? data : [1]);
  },

  subscriptions(state, data) {
    return (state.subscriptions = data.length ? data : [1]);
  },

  contract(state, data) {
    return (state.contract = data);
  },

  contracts(state, data) {
    return (state.contracts = data);
  },

  contacts(state, data) {
    state.contacts = data;
    return state;
  },

  companyLocations(state, data) {
    state.companyLocations = data;
    return state;
  },

  invoices(state, data) {
    return (state.invoices = data);
  },

  invoice(state, data) {
    state.invoice = data;
    return state;
  },

  quotes(state, data) {
    return (state.quotes = data.length ? data : [1]);
  },

  quoteItems(state, data) {
    return (state.quoteItems = data.length ? data : [1]);
  },

  setQuote(state, data) {
    state.quote = data;
  },

  billingCodes(state, data) {
    return (state.billingCodes = data.length ? data : [1]);
  },

  services(state, data) {
    state.services = data;
  },

  setService(state, config) {
    let contracts = state.contracts.filter((contract) => {
      // When multiple contract categories are used for one service.
      if (config.service == 'managedServices') {
        return (
          contract.category == 11 ||
          contract.category == 12 ||
          contract.category == 13 ||
          (contract.category == 20 && contract.status)
        );
      } else {
        return contract.category == config.contractCategory && contract.status;
      }
    });

    if (contracts.length > 0 && config.service != 'software') {
      contracts.forEach((contract) => {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // add 1 because month index starts from 0
        let periodType = '';

        let contractServiceTypes = ['contractServices', 'contractServiceBundles'];

        contractServiceTypes.forEach((type) => {
          if (contract[type].length > 0) {
            contract[type].forEach((contractService) => {
              let service = contractService.service;

              let billingCode = state.billingCodes.find(
                (code) => code.id == service.billing_code_id
              );

              let unit = contractService.units.find((unit) => {
                if (contract.periodType == '2') {
                  var formattedDate = `${year}-${month < 10 ? '0' + month : month}`;

                  return unit.startDate.slice(0, 7) == formattedDate;
                } else if (contract.periodType == '5') {
                  if (unit.endDate.slice(0, 4) == year && unit.endDate.slice(5, 7) >= month) {
                    return unit;
                  } else if (
                    unit.startDate.slice(0, 4) == year &&
                    unit.startDate.slice(5, 7) >= month
                  ) {
                    return unit;
                  }
                }
              });

              switch (parseInt(contract.periodType)) {
                case 2:
                  periodType = 'Monthly';
                  break;
                case 3:
                  periodType = 'Quarterly';
                  break;
                case 4:
                  periodType = 'Semi-Annual';
                  break;
                case 5:
                  periodType = 'Yearly';
                  break;

                default:
                  break;
              }
              if (unit != undefined) {
                state[config.service].push({
                  description: service.name,
                  category: billingCode.name,
                  periodType: periodType,
                  units: unit.units,
                  unitPrice: unit.price / unit.units,
                  totalPrice: unit.price,
                });

                state.services.push({
                  description: service.name,
                  name: config.name,
                  category: config.service,
                  periodType: periodType,
                  units: unit.units,
                  unitPrice: unit.price / unit.units,
                  totalPrice: unit.price,
                });
              }
            });
          }
        });
      });

      if (
        config.service == 'domainHosting' ||
        config.service == 'emailServices' ||
        config.service == 'securitySolutions'
      ) {
        let categories = '';

        state.subscriptions.forEach((subscription) => {
          let configurationItem = state.configurationItems.find((item) => {
            switch (config.service) {
              case 'domainHosting':
                categories = item.category == 4 || item.category == 109 || item.category == 111;
                break;
              case 'emailServices':
                categories = item.category == 110;
                break;
              case 'securitySolutions':
                categories = item.category == 5;
                break;
              case 'software':
                categories = item.category == 112;
                break;

              default:
                break;
            }
            return item.id == subscription.configuration_item_id && categories;
          });

          let category = '';
          let periodType = '';

          if (configurationItem != undefined) {
            switch (parseInt(configurationItem.category)) {
              case 4:
                category = 'Domain';
                break;
              case 5:
                category = 'SSL Certificate';
                break;
              case 109:
                category = 'Webhosting';
                break;
              case 110:
                category = 'Spamfilter';
                break;
              case 111:
                category = 'Maintenance';
                break;
              case 112:
                category = 'Software';
                break;
              default:
                break;
            }

            switch (parseInt(subscription.periodType)) {
              case 2:
                periodType = 'Monthly';
                break;
              case 3:
                periodType = 'Quarterly';
                break;
              case 4:
                periodType = 'Semi-Annual';
                break;
              case 5:
                periodType = 'Yearly';
                break;

              default:
                break;
            }
            state[config.service].push({
              description: configurationItem.description,
              category: category,
              periodType: periodType,
              units: 1,
              unitPrice: subscription.periodPrice,
              totalPrice: subscription.periodPrice,
            });

            state.services.push({
              description: configurationItem.description,
              name: config.name,
              category: config.service,
              periodType: periodType,
              units: 1,
              unitPrice: subscription.periodPrice,
              totalPrice: subscription.periodPrice,
            });
          }
        });
      }
    } else if (config.service == 'software') {
      let configurationItem = state.configurationItems.find((item) => {
        return item.category == 112;
      });

      if (configurationItem != undefined) {
        state[config.service].push({
          description: configurationItem.product.name,
          category: 'Software',
          licenseType: configurationItem.licenseType ? configurationItem.licenseType : '#',
          purchaseDate: configurationItem.purchaseDate ? configurationItem.purchaseDate : '#',
          downloadLink: configurationItem.downloadLink ? configurationItem.downloadLink : '#',
          productKey: configurationItem.serialNumber ? configurationItem.serialNumber : '#',
        });

        state.services.push({
          description: service.name,
          name: config.name,
          category: config.service,
          licenseType: configurationItem.licenseType ? configurationItem.licenseType : '#',
          purchaseDate: configurationItem.purchaseDate ? configurationItem.purchaseDate : '#',
          downloadLink: configurationItem.downloadLink ? configurationItem.downloadLink : '#',
          productKey: configurationItem.serialNumber ? configurationItem.serialNumber : '#',
        });
      }
    }

    // if (!state[config.service].length) {
    //   state[config.service] = '';
    // }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
