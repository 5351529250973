<template>
  <div class="search">
    <!-- SEARCH BUTTON -->
    <div class="search__button" @click="toggleSearch">
      <i class="icon--search"></i>
      <span class="p--regular text-gray-100">Zoek...</span>
    </div>
    <div class="search__container" v-show="showSearch">
      <!-- SEARCH INPUT -->
      <div class="search__container__input">
        <i class="icon--search"></i>
        <input
          class="input input--focus-no"
          id="searchInput"
          type="text"
          placeholder="Zoek"
          v-model="searchKeyword"
          @keyup.enter="search"
        />
        <div class="search__container__input__actions" v-show="searchKeyword.length">
          <span v-show="showErrorMessage" class="p--small text-red-100"
            >Vul minimaal 3 karakters in om te zoeken</span
          >
          <i class="icon--search-cross" @click="resetSearch"></i>
        </div>
      </div>
      <!-- SEARCH CATEGORIES -->
      <div class="search__container__categories" v-show="searchActive">
        <div
          class="search__container__categories__item"
          v-for="category in searchCategories"
          :key="category.name"
          @click="searchCategoryClickHandler(category)"
        >
          <span class="p--small"> {{ category.title }} ({{ category.data.length }})</span>
        </div>
      </div>
      <!-- SEARCH RESULTS -->
      <div class="search__container__results" v-show="searchActive">
        <div
          class="search__container__results__item"
          v-for="(result, i) in searchResults"
          :key="i"
          @click="searchResultClickHandler(result)"
        >
          <div class="search__container__results__item__title">
            <i :class="resultIcon(result.searchCategory)"></i>
            <span class="p--regular">{{ resultTitle(result) }} </span>
          </div>
          <span v-show="result.searchCategory != 'invoice'" class="p--small text-gray-100">{{
            result.description
          }}</span>
          <span v-show="result.searchCategory == 'invoice'" class="p--small text-gray-100">{{
            result.invoiceDate
          }}</span>
        </div>
      </div>
      <!-- SEACH HISTORY -->
      <div class="search__container__history" v-show="!searchActive">
        <div class="search__container__history__header">
          <span class="p--small text-gray-100">Recente zoekopdrachten</span>
          <span class="p--small text-blue-200 cursor--pointer" @click="resetHistory"
            >Verwijder historie</span
          >
        </div>
        <div class="search__container__history__content">
          <div
            class="search__container__history__content__placeholder"
            v-show="!searchHistory.length"
          >
            <span class="p--regular text-gray-100">Geen recente zoekopdrachten</span>
          </div>
          <div
            class="search__container__history__content__searches"
            v-show="searchHistory.length"
            v-for="(keyword, i) in searchHistory"
            :key="i"
          >
            <div
              class="search__container__history__content__searches__item"
              @click="setSearch(keyword)"
            >
              <div class="search__container__history__content__searches__item__content">
                <i class="icon--recent-search"></i>
                <span class="p--regular text-gray-100">{{ keyword }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSearch: false,
      searchActive: false,
      showErrorMessage: false,
      searchKeyword: '',

      searchCategories: [],
      searchResults: [],
      searchHistory: [],
    };
  },

  mounted() {
    let self = this;
    document.body.addEventListener('click', function (event) {
      const search = document.querySelector('.search');
      if (search != null && !search.contains(event.target)) {
        self.showSearch = false;
      }
    });

    const searchHistory = localStorage.getItem('searchHistory');

    if (searchHistory == null) {
      localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));
    } else {
      this.searchHistory = JSON.parse(searchHistory);
    }
  },

  methods: {
    toggleSearch() {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        setTimeout(() => {
          document.getElementById('searchInput').focus();
        }, 50);
      }
    },

    resetHistory() {
      localStorage.removeItem('searchHistory');
      this.searchHistory = [];
    },

    resetSearch() {
      this.searchKeyword = '';
      this.searchActive = false;
    },

    setSearch(keyword) {
      this.searchKeyword = keyword;

      this.search();
    },

    search() {
      if (this.searchKeyword.length >= 3) {
        this.searchCategories = [];
        this.searchResults = [];

        let companyId = this.user.company_id;

        Promise.all([
          this.$store.dispatch('tickets/tickets', companyId),
          this.$store.dispatch('tickets/completedTickets', companyId),
          this.$store.dispatch('projects/projects', companyId),
          this.$store.dispatch('company/billingCodes'),
          this.$store.dispatch('company/contracts', companyId),
          this.$store.dispatch('company/quotes', companyId),
          this.$store.dispatch('company/invoices', companyId),
        ])
          .then(() => {
            let filteredData = {};
            let keyword = this.searchKeyword.toLowerCase();

            // Filter through the tickets for a match with "Ticket number" or "Title".
            filteredData.tickets = this.tickets.filter((ticket) => {
              if (
                ticket.ticketNumber.toLowerCase().includes(keyword) ||
                ticket.title.toLowerCase().includes(keyword)
              ) {
                ticket.searchCategory = 'ticket';
                return true;
              }
            });

            // Filter through the projects for a match with "Project number" or "Project name".
            filteredData.projects = this.projects.filter((project) => {
              if (
                project.projectNumber.toLowerCase().includes(keyword) ||
                project.name.toLowerCase().includes(keyword)
              ) {
                project.searchCategory = 'project';
                return true;
              }
            });

            // Filter through the quotes for a match with "description" or "category".
            filteredData.services = this.services.filter((service) => {
              if (
                service.description.toLowerCase().includes(keyword) ||
                service.name.includes(keyword)
              ) {
                service.searchCategory = 'service';
                return true;
              }
            });

            // Filter through the quotes for a match with "Quote number" or "Title".
            filteredData.quotes = this.quotes.filter((quote) => {
              if (
                (quote.number != null && quote.number.toLowerCase().includes(keyword)) ||
                quote.name.toLowerCase().includes(keyword)
              ) {
                quote.searchCategory = 'quote';
                return true;
              }
            });

            // Filter through the invoices for a match with "Invoice number".
            filteredData.invoices = this.invoices.filter((invoice) => {
              if (invoice.invoiceNumber.toLowerCase().includes(keyword)) {
                invoice.searchCategory = 'invoice';
                return true;
              }
            });

            // Setup the search categories based on if a category has data.
            Object.entries(filteredData).forEach((item) => {
              let category = item[0];
              let data = item[1];

              if (data.length) {
                if (category == 'tickets') {
                  this.searchCategories.push({
                    title: 'Tickets',
                    name: 'tickets',
                    routeName: 'search_results',
                    breadCrumb: { en: 'Tickets: ' + this.keyword, nl: 'Tickets: ' + this.keyword },
                    data: data,
                  });
                } else if (category == 'projects') {
                  this.searchCategories.push({
                    title: 'Projecten',
                    name: 'projects',
                    routeName: 'search_results',
                    breadCrumb: {
                      en: 'Projects: ' + this.keyword,
                      nl: 'Projecten: ' + this.keyword,
                    },
                    data: data,
                  });
                } else if (category == 'services') {
                  this.searchCategories.push({
                    title: 'Diensten',
                    name: 'companies',
                    routeName: 'search_results',
                    breadCrumb: {
                      en: 'Services: ' + this.keyword,
                      nl: 'Diensten: ' + this.keyword,
                    },
                    data: data,
                  });
                } else if (category == 'quotes') {
                  this.searchCategories.push({
                    title: 'Offertes',
                    name: 'quotes',
                    routeName: 'quotes',
                    data: data,
                  });
                } else if (category == 'invoices') {
                  this.searchCategories.push({
                    title: 'Facturen',
                    name: 'invoices',
                    routeName: 'invoices',
                    data: data,
                  });
                }

                this.searchResults = this.searchResults.concat(data);
              }
            });

            this.searchResults.sort((a, b) => {
              const aDateTime = this.parseDate(a.updated_at);
              const bDateTime = this.parseDate(b.updated_at);

              return bDateTime - aDateTime;
            });

            if (this.searchResults.length > 10) {
              this.searchResults.length = 10;
            }

            // Prevent the search history from having more then 15 results
            if (this.searchHistory.length > 15) {
              this.searchHistory.splice(this.searchHistory.length - 1, 1);
            }

            // Find if keyword is already in history to prevent duplicate keywords in search history.
            const keywordIsUsed = this.searchHistory.some(
              (keyword) => keyword == this.searchKeyword
            );

            if (!keywordIsUsed) {
              this.searchHistory.unshift(this.searchKeyword);
            } else if (keywordIsUsed) {
              // When keyword already exists set as first result in history
              const historyIndex = this.searchHistory.findIndex(
                (keyword) => keyword == this.searchKeyword
              );
              this.searchHistory.splice(historyIndex, 1);

              this.searchHistory.unshift(this.searchKeyword);
            }

            localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory));

            if (this.searchResults.length) {
              this.searchActive = true;
            } else {
              this.resetSearch();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.showErrorMessage = true;
        this.searchActive = false;
      }
    },

    searchCategoryClickHandler(category) {
      localStorage.setItem('searchObject', JSON.stringify(category));

      if (this.$route.params.filter != 'search' || this.$route.name != category.routeName) {
        this.$router.push({ name: category.routeName, params: { filter: 'search' } });
      } else if (category.routeName == 'search_results') {
        this.$router.push({
          name: category.routeName,
          params: { type: category.name },
          meta: {
            breadCrumb: category.breadCrumb,
          },
        });
      }

      this.$store.commit('global/reRender');

      this.resetSearch();
      this.showSearch = false;
    },

    searchResultClickHandler(result) {
      switch (result.searchCategory) {
        case 'tickets':
          this.$router.push({ name: 'ticket_details', params: { id: result.ticketNumber } });
          break;
        case 'project':
          this.$router.push({ name: 'project_details', params: { id: result.id } });
          break;
        case 'service':
          this.$router.push({ name: result.category });
          break;
        case 'quote':
          this.$router.push({ name: 'quote_preview', params: { id: result.id } });
          break;
        case 'invoice':
          this.$store.dispatch('company/downloadInvoice', {
            id: result.id,
            number: result.invoiceNumber,
          });
          break;

        default:
          break;
      }

      this.resetSearch();
      this.showSearch = false;
    },

    resultIcon(category) {
      switch (category) {
        case 'ticket':
          return 'icon--tickets';
        case 'project':
          return 'icon--projects';
        case 'service':
          return 'icon--services';
        case 'quote':
          return 'icon--quotes';
        case 'invoice':
          return 'icon--invoices';
        default:
          break;
      }
    },

    resultTitle(data) {
      switch (data.searchCategory) {
        case 'ticket':
          return data.ticketNumber + ': ' + data.title;
        case 'project':
          return data.projectNumber + ': ' + data.name;
        case 'service':
          return data.name;
        case 'quote':
          return data.number + ': ' + data.name;
        case 'invoice':
          return data.invoiceNumber;
        default:
          break;
      }
    },

    parseDate(dateStr) {
      // Split the date string by space to separate the date and time
      const parts = dateStr.split(' ');
      // Split the date and time parts further
      const dateParts = parts[0].split('-');
      const timeParts = parts[1].split(':');

      // Construct a new Date object using parts of the split string
      const year = parseInt(dateParts[2], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[0], 10);
      const hour = parseInt(timeParts[0], 10);
      const minute = parseInt(timeParts[1], 10);
      const second = parseInt(timeParts[2], 10);

      return new Date(year, month, day, hour, minute, second);
    },
  },

  computed: {
    user() {
      return this.$store.getters['login/authUser'];
    },

    tickets() {
      let openTickets = this.$store.getters['tickets/tickets'];
      let completedTickets = this.$store.getters['tickets/completedTickets'];

      return openTickets.concat(completedTickets);
    },

    projects() {
      return this.$store.getters['projects/projects'];
    },

    services() {
      return this.$store.getters['company/services'];
    },

    quotes() {
      return this.$store.getters['company/quotes'];
    },

    invoices() {
      return this.$store.getters['company/invoices'];
    },
  },
};
</script>

<style></style>
