/* BEGIN Packages */
import Vue from 'vue';
import Axios from 'axios';
import VueSweetalert2 from 'vue-sweetalert2';
import Multiselect from 'vue-multiselect';
/* END Packages */

/* BEGIN Config files */
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './assets/lang/index.js';
/* END Config files */

/* BEGIN Global components */
import DataTable from './components/Global/Modules/DataTable.vue';
import Select from './components/Global/Modules/Select.vue';
import Checkbox from './components/Global/Modules/Checkbox.vue';
import TimeTracker from './components/Global/Modules/TimeTracker.vue';
import Tabs from './components/Global/Modules/Tabs.vue';
import Dropdown from './components/Global/Modules/Dropdown.vue';
import Modal from './components/Global/Modules/Modal.vue';
import ProgressBar from './components/Global/Modules/ProgressBar.vue';
import Chart from './components/Global/Modules/Chart.vue';
/* END Global components */

// import Loader from './components/Global/Loader.vue';
// import Smiley from './components/Global/Smiley.vue';
/* END custom VUE components */

/* BEGIN Styling */

// Bootstrap
import 'bootstrap';

// Font Awesome
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
import '@fortawesome/fontawesome-pro/scss/solid.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import '@fortawesome/fontawesome-pro/scss/light.scss';
import '@fortawesome/fontawesome-pro/scss/thin.scss';
import '@fortawesome/fontawesome-pro/scss/brands.scss';
import '@fortawesome/fontawesome-pro/scss/sharp-solid.scss';
// Vue multiselect
import 'vue-multiselect/dist/vue-multiselect.min.css';
// GridStack
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/gridstack-extra.min.css';
// Custom CSS
import './assets/css/main.scss';
/* END Styling */

/* BEGIN API TOKEN SETUP */
Vue.prototype.$http = Axios;

const token = localStorage.getItem('token');
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  Vue.prototype.$http.defaults.withCredentials = true;
}
/* END API TOKEN SETUP */

Vue.prototype.$chart = [];

/* BEGIN global VUE components */
Vue.component('datatable', DataTable);
Vue.component('multiselect', Multiselect);
Vue.component('dropdown', Dropdown);
Vue.component('selection', Select);
Vue.component('checkbox', Checkbox);
Vue.component('timeTracker', TimeTracker);
Vue.component('tabs', Tabs);
Vue.component('modal', Modal);
Vue.component('progress-bar', ProgressBar);
Vue.component('chart', Chart);

/* END global VUE components */

/* BEGIN TRANSLATION */
let locale = localStorage.getItem('lang');

if (locale) {
  Vue.prototype.$http.defaults.headers.common['Accept-Language'] = locale;
} else {
  Vue.prototype.$http.defaults.headers.common['Accept-Language'] = 'nl';
}
/* END TRANSLATION */

/* BEGIN VUE SETUP */
Vue.config.productionTip = false;

Vue.use(VueSweetalert2);

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
/* END VUE SETUP */
