<template>
  <label :for="id" class="flex flex--items-center gap-10 height-px-18 p pointer">
    <input :disabled="disabled" :checked="checked" type="checkbox" :id="id" @click="clickHandler" />
    <span class="checkbox"></span>
    <span class="checkbox--label p--regular">{{ text }}</span>
  </label>
</template>

<script>
export default {
  props: {
    id: String,
    text: String,
    clickHandler: Function,
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
