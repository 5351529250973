<template>
  <div class="progress" :class="'progress--' + type">
    <div class="progress__bar" :class="classHandler()">{{ progressText }}%</div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'bg-blue-200',
    },
    progress: {
      type: Number,
      default: 0,
    },
    progressText: {
      type: Number,
      default: 0,
    },
    type: String,
  },

  methods: {
    classHandler() {
      var typeClass = 'progress--' + this.type + '__bar ';
      var dimensionClass;
      var colorClass;
      var borderClass = '';

      if (this.type == 'horizontal') {
        if (this.progress > 0) {
          dimensionClass = 'width-' + this.progress;

          colorClass = this.color;

          if (this.progress == 100) {
            borderClass = ' border-rd-5';
          }
        } else {
          dimensionClass = 'width-3';

          colorClass = ' ';
        }
      } else if (this.type == 'vertical') {
        if (this.progress > 0) {
          dimensionClass = 'height-' + this.progress;

          colorClass = this.color;

          if (this.progress == 100) {
            borderClass = ' border-rd-3';
          }
        } else {
          dimensionClass = 'height-3 ';

          colorClass = ' ';
        }
      }

      if (this.progress == 100) {
        return typeClass + dimensionClass + ' ' + colorClass + borderClass;
      } else {
        return typeClass + dimensionClass + ' ' + colorClass;
      }
    },
  },
};
</script>

<style></style>
