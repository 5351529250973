import axios from 'axios';
import router from '../../router';
import Vue from 'vue';

const state = {
  token: localStorage.getItem('token') || '',

  user: {},
  company: {},

  authUser: {},

  loading: false,

  loginMessage: '',

  passwordResetRequested: false,
};

const getters = {
  isLoggedIn: (state) => !!state.token,

  authUser: (state) => state.authUser,
  company: (state) => state.company,
  loading: (state) => state.loading,
  loginMessage: (state) => state.loginMessage,

  passwordResetRequested: (state) => state.passwordResetRequested,
};

const actions = {
  login(context, user) {
    context.commit('loginMessage', '');

    axios({
      url: context.rootState.domains.users_api + 'login',
      params: {
        username: user.username,
        password: user.password,
      },
      method: 'GET',
    })
      .then((resp) => {
        const token = resp.data.token;
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = token;
        location.reload();
      })
      .catch((error) => {
        context.commit('loginMessage', error.response.data.message);
        localStorage.removeItem('token');
      });
  },

  requestPassword(context, username) {
    axios
      .get(context.rootState.domains.users_api + `request_password/${username}`)
      .then((response) => {
        context.commit('passwordResetRequested', true);
      })
      .catch((error) => {
        context.commit('loginMessage', error.response.data.message);
      });
  },

  changePassword(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .patch(context.rootState.domains.users_api + 'change_password', {
          emailAddress: credentials.email,
          password: credentials.password,
          password_confirmation: credentials.passwordConfirmation,
          token: credentials.token,
        })
        .then((response) => {
          console.log(response.data);
          resolve(response.data);
          router.push({ name: 'login' });
        })
        .catch((error) => {
          context.commit('loginMessage', error.response.data.message);
          reject(error);
        });
    });
  },

  loggedInUser(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.users_api + 'authenticated/user')
        .then((response) => {
          context.commit('setAuthUser', response.data);

          axios
            .get(
              context.rootState.domains.external_api + `user/company/${response.data.company_id}`
            )
            .then((response) => {
              context.commit('company', response.data);
            });
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  },
};

const mutations = {
  logout(state, message) {
    state.token = '';
    state.loginMessage = message;

    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];

    router.push('/');
  },

  setAuthUser(state, data) {
    data.widget_layout = JSON.parse(data.widget_layout);
    Vue.prototype.$user = data;
    state.authUser = data;
    return state;
  },

  widgetLayout(state, data) {
    state.authUser.widget_layout = data;
  },

  company(state, data) {
    state.company = data;
    return state;
  },

  loginMessage(state, data) {
    return (state.loginMessage = data);
  },

  passwordResetRequested(state, data) {
    state.errorMessage = '';
    return (state.passwordResetRequested = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
