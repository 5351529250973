export const nl = {
  global: {
    settings: 'Instellingen',
    save: 'Opslaan',
    close: 'Sluiten',
    send: 'Versturen',
    accept: 'Accepteren',
    reject: 'Weiger',
    delete: 'Verwijderen',
    user: 'Gebruiker',
    days: 'Dagen',
    month: 'Maand',
    date: 'datum',
    number: 'nummer',
    open: 'Openstaande',
    closed: 'Afgesloten',
    end: 'Eind',
    back: 'Terug',
  },
  status: {
    inProgress: 'In behandeling',
    actionRequired: 'Actie vereist',
    scheduled: 'Ingepland',
    complete: 'Afgehandeld',
    cancelled: 'Geannuleerd',
    approvalPending: 'In afwachting',
    accepted: 'Geaccepteerd',
  },
  notification: {
    resetPassword: {
      success: 'Uw wachtwoord is opnieuw ingesteld',
      error: 'Het is niet gelukt om uw  wachtwoord opnieuw in te stellen',
    },
    ticketReceived: 'Uw ticket is succesvol ontvangen',
    messageSend: 'Uw bericht is succesvol verstuurd',
    quote: {
      accepted: 'De offerte is succesvol geaccepteerd',
      rejected: 'De offerte is succesvol afgewezen',
    },
    reaction: {
      empty: 'Gelieve een opmerking in te voeren',
      send: 'Bedankt voor uw reactie',
    },
    ticket: {
      open: 'Het ticket is succesvol heropend',
      closed: 'Het ticket is succesvol afgesloten',
    },
    objectionSend: 'Uw objectie is succesvol ingedient, we nemen zo snel mogelijk contact op',
  },
  label: {
    title: 'Titel',
    description: 'Beschrijving',
    name: 'Naam',
    attachment: 'Bijlage',
  },
  login: {
    email: 'Email of gebruikersnaam',
    password: 'Wachtwoord',
    login: 'Inloggen',
    emailAddress: 'Email adres',
    requestPassword: 'Vraag nieuw wachtwoord aan',
    forgotPassword: 'Wachtwoord vergeten?',
    forgotPasswordIsSent:
      'U heeft een nieuw wachtwoord aangevraagd. Een link om het wachtwoord te wijzigen is verstuurd naar:',
    or: 'Of',
    support: 'Direct support nodig?',
    for: 'Voor',
    created: 'Gemaakt door',
    terms: 'Gebruiksvoorwaarden',
    privacy: 'Privacybeleid',
    contact: 'Contact ons',
    newPassword: 'Nieuw wachtwoord',
    secondPassword: 'Herhaal nieuw wachtwoord',
    submitNewPassword: 'Nieuw wachtwoord instellen',
  },
  header: {
    profile: 'Profiel',
    signOut: 'Uitloggen',
    searchPlaceholder: 'Tickets zoeken...',
  },
  menu: {
    dashboard: 'Dashboard',
    tickets: 'Tickets',
    newTicket: 'Nieuw',
    projects: 'Projecten',
    quotes: 'Offertes',
    invoices: 'Facturen',
  },
  footer: {
    lastSync: 'Laatst gesynchroniseerd om',
  },
  datatable: {
    placeholder: 'Geen data gevonden',
    columns: 'Kolommen',
  },
  request: {
    submit: 'Indienen',
    perUnit: 'Per Stuk',
    price: 'Prijs',
  },
  tickets: {
    ticketTypes: 'Soort tickets',
    allTickets: 'Alle tickets',
    openTickets: 'Openstaande tickets',
    closedTickets: 'Afgehandelde tickets',
  },
  ticket: {
    created: 'Aangemaakt',
    notes: 'Notities',
    attachments: 'Bijlagen',
    addNote: 'Notitie toevoegen',
  },
  projects: {
    lead: 'leider',
    waitingOnCustomer: 'Wachtend op klant (werkdagen)',
    waitingFor: 'Wachten voor',
  },
  widgetSettings: {
    ticketTitle: 'Ticket instellingen',
    serviceTitle: 'Dienst kiezer',
    notVisible: 'Niet zichtbaar',
    visible: 'Zichtbaar',
  },
  services: {
    services: 'Diensten',
    domainHosting: 'Domein & Hosting',
    electronicSecurity: 'Electronische Beveiliging',
    backupServices: 'Backup Diensten',
    cloudSolutions: 'Cloud Oplossingen',
    connectivity: 'Verbindingen',
    emailServices: 'Email Diensten',
    managedItServices: 'Beheerde IT Diensten',
    securitySolutions: 'Beveiligingsoplossingen',
    telephony: 'Telefonie',
    software: 'Software',
  },
  quotes: {
    quote: 'Offerte',
    expiration: 'Verval',
    beingPrepared: 'Wordt voorbereid',
    olderQuotes: 'Klik hier om oudere offertes te bekijken',
    acceptQuote:
      "Door op 'Accepteren' te klikken, bevestigt u dat u akkoord gaat met de Offerte en de voorwaarden ervan. Dit dient als bewijs van uw toestemming.",
    rejectQuote:
      "Door op 'Weigeren' te klikken, bevestigt u dat u de Offerte en de voorwaarden ervan verwerpt. Dit dient als bewijs van uw toestemming.",
  },
  subscriptions: {
    category: 'Categorie',
    startDate: 'Start Datum',
  },
};
