import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import router from '../../router';

const state = {
  forms: [],
  form: {},
  fields: {},
  blocks: {},
  data: [],
  dataReady: false,
  errors: {},

  requests: [],
  request: {},

  applications: [],
  types: [],
  presets: [],

  products: [],
  tags: [],

  files: [],

  render: false,

  showRequest: false,
};

const getters = {
  forms: (state) => state.forms,
  form: (state) => state.form,
  fields: (state) => state.fields,
  blocks: (state) => state.blocks,
  data: (state) => state.data,
  dataReady: (state) => state.dataReady,
  errors: (state) => state.errors,

  requests: (state) => state.requests,
  request: (state) => state.request,

  applications: (state) => state.applications,
  types: (state) => state.types,
  presets: (state) => state.presets,

  tags: (state) => state.tags,
  products: (state) => state.products,

  files: (state) => state.files,

  render: (state) => state.render,

  showRequest: (state) => state.showRequest,
};

const actions = {
  forms(context, id) {
    axios.get(context.rootState.domains.forms_api + `forms/${id}`).then((response) => {
      context.commit('setForms', response.data);
    });
  },

  form(context, id) {
    axios.get(context.rootState.domains.forms_api + `form/${id}`).then((response) => {
      context.commit('setForm', response.data);
    });
  },

  fields(context, id) {
    axios.get(context.rootState.domains.forms_api + `fields/${id}`).then((response) => {
      context.commit('setFields', response.data);
    });
  },

  blocks(context, id) {
    axios.get(context.rootState.domains.forms_api + `blocks/${id}`).then((resp) => {
      context.commit('setBlocks', resp.data);
    });
  },

  uploadFile(context, data) {
    let formData = new FormData();
    formData.append('file', data);

    axios.post(context.rootState.domains.requests_api + `file/upload`, formData).then((resp) => {
      console.log(resp);
    });
  },

  requests(context, id) {
    axios.get(context.rootState.domains.requests_api + `requests/${id}`).then((response) => {
      context.commit('setRequests', response.data);
    });
  },

  saveRequest(context, data) {
    axios.post(context.rootState.domains.requests_api + 'request', data).then((response) => {
      data.form.code_snippet = response.data.code_snippet;
      axios
        .post(context.rootState.domains.users_api.autotask + 'ticket/request', {
          data: data.data,
          user: data.user,
          company: data.client,
          form: data.form,
        })
        .then((resp) => {
          if (resp.data?.itemId) {
            Swal.fire({
              toast: true,
              position: 'top-right',
              showConfirmButton: false,
              title: 'Aanvraag is succesvol verzonden!',
              icon: 'success',
              timer: 1500,
              customClass: {
                container: 'mt-5',
                popup: 'bg-dark',
              },
            }).then(() => {
              location.reload();
            });

            axios
              .patch(context.rootState.domains.requests_api + 'request/ticket_number', {
                id: response.data.id,
                ticketNumber: resp.data.itemId,
              })
              .then((response) => {});
          }
        });
    });
  },

  // Retrieve all applications from the database
  getApplications(context) {
    axios.get(context.rootState.domains.products_api + 'applications').then((resp) => {
      context.commit('setApplications', resp.data);
    });
  },

  // Retrieve all types (Application Tags) from database
  getTypes(context) {
    axios.get(context.rootState.domains.products_api + 'types').then((response) => {
      context.commit('setTypes', response.data);
    });
  },

  // Retrieve all Presets from database
  getPresets(context) {
    axios.get(context.rootState.domains.products_api + 'presets').then((resp) => {
      context.commit('setPresets', resp.data);
    });
  },

  products(context) {
    axios
      .get(context.rootState.domains.products_api + 'products')
      .then((response) => {
        context.commit('setProducts', response.data);
      })
      .catch((resp) => {});
  },

  getTags(context) {
    axios
      .get(context.rootState.domains.products_api + 'tags')
      .then((response) => {
        context.commit('setTags', response.data);
      })
      .catch((resp) => {});
  },
};

const mutations = {
  setForms(state, data) {
    state.forms = data;
  },

  setForm(state, data) {
    state.form = data;
  },

  setFields(state, data) {
    state.fields = data;
    state.data = {};

    let language = state.form.language;

    state.fields.forEach((field) => {
      switch (field.type) {
        case 'price':
          state.data[field.id] = {
            id: field.id,
            type: field.type,
            label: field.label[language],
            amount: null,
            price: null,
          };

          break;
        case 'checkbox':
          state.data[field.id] = {
            id: field.id,
            type: field.type,
            label: field.label[language],
            value: {},
          };

          break;
        case 'list':
          let formattedList = [];

          field.values.forEach((option) => {
            formattedList.push(option[language]);
          });

          state.data[field.id] = {
            id: field.id,
            type: field.type,
            label: field.label[language],
            value: formattedList,
          };

          break;
        case 'application':
          state.data[field.id] = {
            id: field.id,
            type: field.type,
            label: field.label[language],
            value: [...field.values],
          };
          break;
        case 'permissions':
          state.data[field.id] = {
            id: field.id,
            type: field.type,
            label: field.label[language],
            value: [],
          };
          break;
        case 'product-info':
        case 'empty-space':
        case 'url':
          break;
        default:
          state.data[field.id] = {
            id: field.id,
            type: field.type,
            label: field.label[language],
            value: '',
          };
          break;
      }
    });
    state.dataReady = true;
    return state;
  },

  setBlocks(state, data) {
    return (state.blocks = data);
  },

  setErrors(state, data) {
    if (data.length) {
      return (state.errors[data[1]] = data[0]);
    } else {
      return (state.errors = data);
    }
  },

  setRequests(state, data) {
    state.requests = data;
  },

  setRequest(state, data) {
    state.request = data;
  },

  showForm(state, data) {
    return (state.showForm = data);
  },

  setRender(state) {
    if (state.render) {
      return (state.render = false);
    } else {
      return (state.render = true);
    }
  },

  setApplications(state, data) {
    return (state.applications = data);
  },

  setTypes(state, data) {
    return (state.types = data);
  },

  setPresets(state, data) {
    return (state.presets = data);
  },

  setProducts(state, data) {
    return (state.products = data);
  },

  setTags(state, data) {
    return (state.tags = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
