<template>
  <div class="flex flex--column flex--justify-center">
    <img
      class="width-px-163 height-px-163 margin--bottom-9 margin--auto-left margin--auto-right"
      src="../../../assets/images/smilyface.png"
      alt="EPIC IT smiley face"
    />
    <h4 class="caption">{{ captionTitle }}</h4>
    <p class="caption">
      {{ caption }}
      <span class="caption__link" v-if="captionLink" @click="clickHandler">{{ captionLink }}</span>
    </p>
  </div>
</template>

<script>
export default {
  props: ['caption', 'captionTitle', 'captionLink', 'clickHandler'],
};
</script>
