import Vue from 'vue';
import Vuex from 'vuex';

import login from './modules/login.js';
import requests from './modules/requests.js';
import conforms from './modules/conforms.js';
import company from './modules/company.js';
import global from './modules/global.js';
import tickets from './modules/tickets.js';
import widgets from './modules/widgets.js';
import projects from './modules/projects.js';

Vue.use(Vuex);

const state = {
  domains: {
    users_api: process.env.VUE_APP_USERS_API_ENDPOINT,
    forms_api: process.env.VUE_APP_FORMS_API_ENDPOINT,
    requests_api: process.env.VUE_APP_REQUESTS_API_ENDPOINT,
    products_api: process.env.VUE_APP_PRODUCTS_API_ENDPOINT,
    external_api: process.env.VUE_APP_EXTERNAL_API_ENDPOINT,
  },
};

export default new Vuex.Store({
  state,
  modules: {
    login,
    requests,
    conforms,
    company,
    global,
    tickets,
    widgets,
    projects,
  },
});
