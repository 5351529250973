<template>
  <footer class="footer">
    <p class="">{{ $t('footer.lastSync') }} {{ lastSyncTime }}</p>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      lastSyncTime: 0,
    };
  },

  mounted() {
    if (this.$route.name != 'login' && this.$route.name != 'reset_password') {
      // Set time of last data synchronization
      this.widgetDataSync('setup');

      // Synchronize data every 5 minutes
      setInterval(() => {
        this.widgetDataSync('interval');
      }, 300000);
    }
  },

  methods: {
    widgetDataSync(type) {
      const currentTime = new Date(Date.now());
      const hours = currentTime.getHours().toString().padStart(2, '0');
      const minutes = currentTime.getMinutes().toString().padStart(2, '0');

      this.lastSyncTime = `${hours}:${minutes}`;

      if (type == 'interval') {
        const promises = [
          this.$store.dispatch('company/contracts', this.user.company_id),
          this.$store.dispatch('company/subscriptions', this.user.company_id),
          this.$store.dispatch('tickets/tickets', this.user.company_id),
        ];

        // Wait till all data is fetched then reload charts and tables.
        Promise.all(promises)
          .then(() => {
            this.$store.commit('widgets/dataSynced');
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters['login/authUser'];
    },
  },
};
</script>
