// Node Packages
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index.js';

import routes from './routes.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters['login/isLoggedIn'];

  const mainPages = ['tickets', 'projects', 'services'];

  Vue.nextTick(() => {
    document.title = to.meta.title;
  });

  // Prevent navigating to change_password without a token
  if (to.path.includes('change_password') && Object.keys(to.query).length) {
    // If the user is still logged in when navigating to the password change page, logout the user.
    if (isLoggedIn) {
      store.dispatch('global/logout');
    }

    // Navigate to our own registered password change route
    router.replace({
      name: 'change_password',
      params: {
        token: to.query.token,
        email: to.query.email,
      },
    });

    next();
    return;
  }

  // Default navigation handling
  if (isLoggedIn && to.matched.some((record) => record.meta.requiresAuth)) {
    // Prevents navigation to main pages
    if (mainPages.some((mainPage) => mainPage == to.name)) {
      return;
    }
    next();
  }
  // When trying to navigate to routes that require no auth when still logged in navigate user back to dashboard.
  else if (isLoggedIn) {
    return router.push({ name: 'dashboard' });
  } else if (!isLoggedIn) {
    // Allow navigating to password change page when parameters are present.
    if (to.name == 'change_password' && Object.keys(to.params).length) {
      next();
      return;
    }

    // Prevent the user from navigating to routes that requires no authentication when there are no parameters or queries inside the url except from the login.
    if (to.name != 'login' && (_.isEmpty(from.params) || _.isEmpty(from.query))) {
      return router.push({ name: 'login' });
    }
    next();
    return;
  }
});

export default router;
