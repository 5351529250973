<template>
  <aside class="sidebar sidebar--divider">
    <div class="sidebar__menu">
      <div :class="{ 'has-submenu': item.children.length }" v-for="(item, i) in menu()" :key="i">
        <router-link class="a" activeClass="active" :to="item.href">
          <div class="sidebar__menu__item" @click="toggleDropdown(i)">
            <div class="sidebar__menu__item__content">
              <div class="sidebar__menu__item__content__icon">
                <i :class="item.icon"></i>
              </div>
              <div class="sidebar__menu__item__content__title">
                <span class="p--regular">{{ item.title }}</span>
              </div>
            </div>
            <div v-if="item.children.length" class="sidebar__menu__item__carot">
              <i v-if="dropdown[i]" class="icon--carot-up"></i>
              <i v-else class="icon--carot-down"></i>
            </div>
          </div>
        </router-link> 

        <div class="sidebar__menu__dropdown" v-show="dropdown[i]" v-if="item.children.length">
          <div
            class="sidebar__menu__dropdown__item"
            v-for="child in item.children"
            :key="child.title"
          >
            <router-link
              class="a"
              activeClass="active"
              :to="item.href + child.href"
              :class="setExactRouteActive(item.href + child.href)"
            >
              <div class="sidebar__menu__dropdown__item__content" :key="reRender">
                <!-- Active border element -->
                <div class="sidebar__menu__dropdown__item__content--active-border no"></div>
                <!-- Active arrow element -->
                <div class="sidebar__menu__dropdown__item__content--active-arrow">
                  <i class="icon--sidebar-active-arrow"></i>
                </div>
                <!-- Hover arrow element -->
                <div class="sidebar__menu__dropdown__item__content__hover--icon">
                  <i class="icon--sidebar-hover-arrow"></i>
                </div>
                <div class="sidebar__menu__dropdown__item__content__title">
                  <span class="p--regular">{{ child.title }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  data() {
    return {
      dropdown: new Array(this.menu.length).fill(false),
      reRender: false,
    };
  },

  mounted() {
    let dropdownState = localStorage.getItem('dropdown');

    if (dropdownState == null) {
      // Save sidebar state in local storage
      localStorage.setItem('dropdown', JSON.stringify(this.dropdown));
    } else {
      this.dropdown = JSON.parse(dropdownState);
    }

    this.setActiveBorder();
  },

  watch: {
    $route: function () {
      this.setActiveBorder();
    },
  },

  methods: {
    setActiveBorder() {
      this.$nextTick(() => {
        if (this.$route.name != null && this.$route.name != 'login') {
          let activeLink = document.querySelector('.router-link-exact-active');

          if (activeLink) {
            const activeChild = activeLink.parentNode;
            const children = Array.from(activeChild.parentNode.children);
            const indexChild = children.indexOf(activeChild);

            let activeBorders = document.querySelectorAll(
              '.sidebar__menu__dropdown__item__content--active-border'
            );

            for (let i = 0; i < activeBorders.length; i++) {
              activeBorders[i].classList.remove('yes');
              activeBorders[i].classList.add('no');
            }

            if (activeChild.classList.length) {
              let filteredActiveBorders = children.slice(0, indexChild);

              for (let i = 0; i < filteredActiveBorders.length; i++) {
                let activeBorder = filteredActiveBorders[i].querySelector(
                  '.sidebar__menu__dropdown__item__content--active-border'
                );

                if (activeBorder) {
                  activeBorder.classList.remove('no');
                  activeBorder.classList.add('yes');
                }
              }
            }
          }
        }
      });
    },

    setExactRouteActive(route) {
      if (this.$route.path.includes(route)) {
        return 'router-link-exact-active';
      }
    },

    toggleDropdown(i) {
      if (this.dropdown[i] == undefined) {
        this.dropdown.fill(false);
        this.dropdown[i] = true;
      } else if (this.dropdown[i]) {
        this.dropdown[i] = false;
      } else {
        this.dropdown.fill(false);
        this.dropdown[i] = true;
      }

      this.dropdown = [...this.dropdown];

      localStorage.setItem('dropdown', JSON.stringify(this.dropdown));
    },

    menu() {
      return this.defaultMenu;
    },
  },

  computed: {
    defaultMenu() {
      return [
        {
          title: this.$t('menu.dashboard'),
          icon: 'icon--dashboard',
          href: '/dashboard',
          children: [],
        },

        {
          title: this.$t('menu.tickets'),
          icon: 'icon--tickets',
          href: '/tickets',
          children: [
            { href: '/new', title: this.$t('menu.newTicket') },
            { href: '/open', title: this.$t('global.open') },
            { href: '/closed', title: this.$t('global.closed') },
          ],
        },
        {
          title: this.$t('menu.projects'),
          icon: 'icon--projects',
          href: '/projects',
          children: [
            { href: '/open', title: this.$t('global.open') },
            { href: '/closed', title: this.$t('global.closed') },
          ],
        },
        {
          title: this.$t('services.services'),
          icon: 'icon--services',
          href: '/services',
          children: [
            { href: '/backup_services', title: this.$t('services.backupServices') },
            { href: '/cloud_solutions', title: this.$t('services.cloudSolutions') },
            { href: '/connectivity', title: this.$t('services.connectivity') },
            { href: '/domain_hosting', title: this.$t('services.domainHosting') },
            { href: '/electronic_security', title: this.$t('services.electronicSecurity') },
            { href: '/email_services', title: this.$t('services.emailServices') },
            { href: '/managed_it_services', title: this.$t('services.managedItServices') },
            { href: '/security_solutions', title: this.$t('services.securitySolutions') },
            { href: '/software', title: this.$t('services.software') },
            { href: '/telephony', title: this.$t('services.telephony') },
          ],
        },
        {
          title: this.$t('menu.quotes'),
          icon: 'icon--quotes',
          href: '/quotes',
          children: [],
        },
        {
          title: this.$t('menu.invoices'),
          icon: 'icon--invoices',
          href: '/invoices',
          children: [],
        },
        // {
        // 	title: this.$t("menu.contract"),
        // 	icon: " mx-auto fa-light fa-file-contract fa-2xl",
        // 	href: "/contracts",
        // 	dropdown: false,
        // },
        // {
        // 	title: this.$t("menu.webshop"),
        // 	icon: " mx-auto fa-light fa-cart-shopping fa-xl",
        // 	href: "/webshop",
        // 	dropdown: true,
        // 	children: [{ href: "/hardware", title: this.$t("menu.hardwareSoftware") }],
        // },
      ];
    },
  },
};
</script>
