<template>
  <div class="dropdown" ref="dropdown">
    <div
      :class="`flex flex__item-center flex--justify-between gap-15 padding-x-9 border-rd-5 bg--dark-200 width-px-${
        buttonWidth ? buttonWidth : width
      }`"
      @click="toggleActive"
    >
      <button class="dropdown__button p--regular padding-y-6 bg--dark-200">
        {{ title }}
      </button>
      <i class="icon--drop-down-arrow"></i>
    </div>
    <div
      :class="`dropdown--variation-user shadow border-rd-5 width-px-${
        dropDownWidth ? dropDownWidth : width
      } margin--top-${spacing}`"
      v-show="active"
    >
      <div
        class="flex padding-5 flex--column flex--justify-center flex--items-start gap-3 border-bottom-1 border-dark-100"
      >
        <p
          v-for="option in options"
          :key="option.key"
          @click="option.function"
          class="p--regular padding-5 cursor--pointer"
        >
          {{ option.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
  props: {
    title: String,
    options: Array,
    buttonWidth: String,
    dropDownWidth: String,
    width: String,
    spacing: String,
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    toggleActive(event) {
      this.active = !this.active;
      // Prevent click from immediately triggering handleClickOutside
      event.stopPropagation();
    },
    handleClickOutside(event) {
      // If the click is outside the dropdown, close it
      if (this.active && !this.$refs.dropdown.contains(event.target)) {
        this.active = false;
      }
    },
  },
};
</script>
