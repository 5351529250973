import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Vue from 'vue';

const state = {
  version: 'v0.1.9',
  headerDropdown: {
    notifications: false,
    userSettings: false,
    language: false,
  },

  notifications: [],
  allNotifications: [],

  language: null,

  dataLoaded: false,
  reRender: 0,

  resources: [],

  activeSelects: {},

  categories: [
    { text: 'Storing', value: 1 },
    {
      text: 'Wijziging',
      value: 2,
    },
  ],
};

const getters = {
  version: (state) => state.version,
  collapsed: (state) => state.collapsed,
  language: (state) => state.language,

  dataLoaded: (state) => state.dataLoaded,
  reRender: (state) => state.reRender,

  resources: (state) => state.resources,

  headerDropdown: (state) => state.headerDropdown,

  notifications: (state) => state.notifications,
  allNotifications: (state) => state.allNotifications,

  activeSelects: (state) => (id) => {
    return state.activeSelects[id];
  },

  columns: (state, getters, rootState, rootGetters) => (defaultColumns, id) => {
    let layout = rootState['login'].authUser.data_table_layouts.find((layout) => layout.name == id);

    if (layout == undefined) {
      return defaultColumns;
    } else {
      let columns = defaultColumns.map((defaultColumn) => {
        let column = layout.columns.find(
          (item) =>
            (item.title == null ? '' : item.title) == defaultColumn.title &&
            item.field == defaultColumn.field
        );

        if (column.title == null) {
          column.title = '';
        }

        return { ...defaultColumn, ...column };
      });

      return columns.sort((a, b) => a.position - b.position);
    }
  },

  searchHandler: (state) => (page) => {
    const searchObject = JSON.parse(localStorage.getItem('searchObject'));

    if (
      (page == 'search_results' && searchObject.name == 'tickets') ||
      searchObject.name == 'projects' ||
      searchObject.name == 'services'
    ) {
      return searchObject.data;
    } else if (page == 'quotes' && searchObject.name == 'quotes') {
      return searchObject.data;
    } else if (page == 'invoices' && searchObject.name == 'invoices') {
      return searchObject.data;
    } else {
      return [];
    }
  },
};

const actions = {
  async alert(context, options) {
    // options[0] = title
    // options[1] = icon

    await Swal.fire({
      toast: true,
      timer: 30000,
      position: 'top-right',
      showCloseButton: 'true',
      timerProgressBar: true,
      showConfirmButton: false,
      title: options[0],
      iconHtml: '<i class="swal2-' + options[1] + '"></i>',
      customClass: {
        container: 'alert-message',
        icon: 'border-0',
      },
    });
  },

  resources(context) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `company_resources`)
        .then((response) => {
          context.commit('resources', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error);
        });
    });
  },

  createDataTableLayout(context, data) {
    axios
      .post(context.rootState.domains.users_api + `data_table_layouts`, data)
      .then((response) => {
        context.rootState['login'].authUser.data_table_layouts = response.data;
      })
      .catch((error) => {});
  },

  updateDataTableLayout(context, data) {
    axios
      .patch(context.rootState.domains.users_api + `data_table_layouts`, data)
      .then((response) => {
        context.rootState['login'].authUser.data_table_layouts = response.data;
      })
      .catch((error) => {});
  },

  notification(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(context.rootState.domains.users_api + `notifications`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  notifications(context, id) {
    return new Promise((resolve) => {
      axios
        .get(context.rootState.domains.users_api + `notifications/user/${id}`)
        .then((response) => {
          context.commit(
            'notifications',
            response.data.filter((notification) => notification.opened == 0)
          );
          context.commit('allNotifications', response.data);
          resolve(response.data.filter((notification) => notification.opened == 0));
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },

  closeNotification(context, id) {
    return new Promise((resolve) => {
      axios
        .patch(context.rootState.domains.users_api + `notifications/close`, {
          id: id,
        })
        .then((response) => {
          resolve(response);
        });
    });
  },
};

const mutations = {
  dataLoaded(state, data) {
    state.dataLoaded = data;
  },

  reRender(state) {
    state.reRender += 1;
  },

  resources(state, data) {
    state.resources = data;
  },

  headerDropdown(state, type) {
    if (state.headerDropdown[type]) {
      state.headerDropdown[type] = false;
    } else {
      Object.keys(state.headerDropdown).forEach((v) => (state.headerDropdown[v] = false));

      state.headerDropdown[type] = true;
    }
  },

  activeSelect(state, data) {
    Vue.set(state.activeSelects, data.id, data.value);
  },

  notifications(state, data) {
    state.notifications = data;
  },

  allNotifications(state, data) {
    state.allNotifications = data;
  },

  setInitialLanguage(state) {
    if (navigator.language == 'en') {
      return (state.language = 'en');
    } else if (navigator.language == 'nl-NL') {
      return (state.language = 'nl');
    } else {
      return (state.language = 'nl');
    }
  },

  toggleLanguage(state, data) {
    return (state.language = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
