<template>
  <main class="flex flex--column">
    <Header class="flex flex--row" v-if="isLoggedIn && noPreview" />
    <div :class="isLoggedIn && noPreview ? 'overflow flex flex--row bg--dark-200' : ''">
      <Sidebar class="flex flex--column bg--dark-100" v-if="isLoggedIn && noPreview" />
      <div :class="isLoggedIn && noPreview ? 'content ' : ''">
        <router-view v-if="dataLoaded || !isLoggedIn" :key="reRender" />

        <!-- <Footer v-show="(isLoggedIn && isDashboard) || isTickets" /> -->
      </div>
    </div>
  </main>
</template>
<script>
import Header from './components/Global/Header.vue';
import Footer from './components/Global/Footer.vue';
import Sidebar from './components/Global/Sidebar.vue';

import actions from './store/actions';

export default {
  components: { Header, Footer, Sidebar },
  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      warningTimer: null,
      logoutTimer: null,
    };
  },

  created: function () {
    if (this.isLoggedIn && (this.$route.name != 'login' || this.$route.name != 'reset_password')) {
      this.$store
        .dispatch('login/loggedInUser')
        .then((resp) => {
          this.fetchData();

          this.$store.dispatch('global/notifications', resp.id);
        })
        .catch((status) => {
          console.log(status);
          if (status === 401) {
            this.$store.commit('login/logout');

            if (this.$route.name != 'login' && this.$route.name != 'reset_password') {
              this.$router.push({ name: 'login' });
            }
          }
        });
    }
  },
  mounted() {
    this.$store.commit('global/setInitialLanguage');

    this.events.forEach((element) => {
      window.addEventListener(element, this.resetTimer);
    }, this);

    this.setTimers();

    // Listener is set for when a Quote is accepted or rejected to then reload the page.
    window.addEventListener('storage', this.handleStorageChange);
  },

  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener('storage', this.handleStorageChange);
  },

  watch: {
    $route: function () {
      this.fetchData();
    },
  },

  methods: {
    fetchData() {
      this.$store.commit('global/dataLoaded', false);

      if (this.user.company_id != undefined) {
        const id = this.user.company_id;
        const routeName = this.$route.name;

        const routeActions = {};

        // This will contain the actions needed to be dispatched.
        let dispatches = (routeActions[routeName] = []);

        dispatches.push(actions.contacts);

        // Dispatch actions based on the route and state is filled or not (const actions is located in store/actions.js).
        switch (routeName) {
          case 'dashboard':
            dispatches.push(actions.tickets);
            dispatches.push(actions.contracts);
            dispatches.push(actions.subscriptions);
            dispatches.push(actions.billingCodes);

            break;

          case 'new_ticket':
            dispatches.push(actions.tickets);
            dispatches.push(actions.completedTickets);
            dispatches.push(actions.companyLocations);

          case 'open_tickets':
          case 'completed_tickets':
          case 'ticket_details':
            dispatches.push(actions.tickets);
            dispatches.push(actions.completedTickets);
            dispatches.push(actions.companyLocations);
            break;
          case 'open_projects':
          case 'closed_projects':
          case 'project_details':
            dispatches.push(actions.projects);
            dispatches.push(actions.resources);
            break;
          case 'domain_hosting':
          case 'electronic_security':
          case 'backup_services':
          case 'cloud_solutions':
          case 'connectivity':
          case 'email_services':
          case 'managed_it_services':
          case 'security_solutions':
          case 'software':
          case 'telephony':
            dispatches.push(actions.contracts);
            dispatches.push(actions.subscriptions);
            dispatches.push(actions.configurationItems);
            dispatches.push(actions.billingCodes);
            break;
          case 'invoices':
            dispatches.push(actions.invoices);
            break;
          case 'quotes':
          case 'quote_preview':
            dispatches.push(actions.quotes);
            dispatches.push(actions.resources);
            break;
          default:
            break;
        }

        const actionsToDispatch = routeActions[routeName] || [];

        const promises = actionsToDispatch.map(({ action, getter }) => {
          // Check if the data is already available in the store
          if (!Object.keys(this.$store.getters[getter]).length) {
            return this.$store.dispatch(action, id);
          }
          return Promise.resolve(); // Data is already available, resolve immediately
        });

        Promise.all(promises)
          .then(() => {
            this.$store.commit('global/dataLoaded', true);
          })
          .catch((status) => {
            if (status == 401) {
              this.$store.commit(
                'global/logout',
                'Uw aanmeldingstoken is niet meer geldig. Log alstublieft opnieuw in.'
              );
            }
          });
      }
    },

    handleStorageChange(event) {
      if (event.key === 'quoteIsProcessed' && event.newValue === 'true') {
        localStorage.removeItem('quoteIsProcessed');

        location.reload();
      }
    },

    setTimers() {
      this.warningTimer = setTimeout(this.warningMessage, 6000000); //14 min for normal cases
      this.logoutTimer = setTimeout(this.logoutUser, 7200000); //15 min for normal cases
    },

    warningMessage() {
      this.$store.dispatch('global/alert', [
        'U word over 20 minuten automatisch uitgelogd',
        'warning',
      ]);
    },

    logoutUser() {
      this.$store.commit('login/logout', 'U bent afgemeld');
    },

    resetTimer() {
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
  },

  computed: {
    user() {
      return this.$store.getters['login/authUser'];
    },

    isLoggedIn: function () {
      return this.$store.getters['login/isLoggedIn'];
    },

    noPreview: function () {
      return this.$route.name != 'quote_preview';
    },

    collapsed: function () {
      return this.$store.getters['global/collapsed'];
    },

    dataLoaded: function () {
      return this.$store.getters['global/dataLoaded'];
    },

    reRender: function () {
      return this.$store.getters['global/reRender'];
    },
  },
};
</script>
