import axios from 'axios';
import router from '../../router';

const state = {
  ticket: {},
  tickets: [],
  completedTickets: [],
  filteredTickets: [],

  ticketDetails: false,
  hoursToBill: [],
  ticketNotes: [],

  categories: [
    { text: 'Storing', value: 2 },
    {
      text: 'Wijziging',
      value: 4,
    },
  ],
};

const getters = {
  ticket: (state) => state.ticket,
  tickets: (state) => state.tickets,
  completedTickets: (state) => state.completedTickets,
  filteredTickets: (state) => state.filteredTickets,
  ticketNotes: (state) => state.ticketNotes,
  hoursToBill: (state) => state.hoursToBill,
  categories: (state) => state.categories,
};

const actions = {
  tickets(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `tickets/${id}`)
        .then((response) => {
          context.commit('tickets', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  completedTickets(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `tickets/${id}/completed`)
        .then((response) => {
          context.commit('completedTickets', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  ticketInfo(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `ticket/${id}`)
        .then((response) => {
          context.commit('setTicket', response.data.ticket);
          context.commit('fromDate', response.data.invoice);
          context.commit('company/invoice', response.data.invoice, { root: true });

          context.dispatch('notes', id).then(() => {
            resolve(response.data);
          });
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error);
        });
    });
  },

  notes(context, id) {
    return new Promise((resolve, reject) => {
      axios.get(context.rootState.domains.external_api + `notes/${id}`).then((response) => {
        context.commit('ticketNotes', [
          response.data.ticket_notes.items,
          response.data.time_entries.items,
        ]);

        resolve(response.data);

        context.commit('hoursToBill', response.data.time_entries.items);
      });
    });
  },

  ticketStatus(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(context.rootState.domains.external_api + `ticket/status`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error);
        });
    });
  },

  addNote(context, note) {
    return new Promise((resolve, reject) => {
      const data = new FormData();

      data.append('id', note[0].id);
      data.append('title', note[0].title);
      data.append('email', note[0].email);
      data.append('description', note[0].description);
      data.append('ticketStatus', note[0].ticketStatus);
      data.append('attachment', note[0].attachment.file);

      axios.post(context.rootState.domains.external_api + 'add_note', data).then((response) => {
        context.commit('newTicketNote', response.data.items[0]);
        resolve(true);
      });
    });
  },

  objectionOnTicket(context, note) {
    const data = new FormData();
    data.append('title', note[0].title);
    data.append('description', note[0].description);
    data.append('email', note[0].email);
    data.append('ticketNumber', note[0].ticketNumber);
    axios
      .post(context.rootState.domains.external_api + 'ticket/objection', data)
      .then((response) => {
        router.push({ name: 'completed_tickets' });
      });
  },

  changeTicketStatus(context, note) {
    const data = new FormData();
    data.append('id', note[0].id);
    data.append('title', note[0].title);
    data.append('email', note[0].email);
    data.append('description', note[0].description);
    data.append('ticketStatus', note[0].ticketStatus);

    axios.post(context.rootState.domains.external_api + 'ticket/status', data).then((response) => {
      context.dispatch('tickets', note[2]);
      router.push({ name: 'open_tickets' });
    });
  },
};

const mutations = {
  tickets(state, data) {
    state.tickets = data.sort(function (a, b) {
      return new Date(b.firstResponseDateTime) - new Date(a.firstResponseDateTime);
    });
    return state;
  },

  completedTickets(state, data) {
    state.completedTickets = data.sort(function (a, b) {
      return new Date(b.firstResponseDateTime) - new Date(a.firstResponseDateTime);
    });
    return state;
  },

  filteredTickets(state, data) {
    return (state.filteredTickets = data);
  },

  searchTickets(state, keyword) {
    let tickets = state.completedTickets.concat(state.tickets);
    state.filteredTickets = tickets.filter((ticket) => {
      return (
        ticket.ticketNumber.toLowerCase().includes(keyword.toLowerCase()) ||
        ticket.title.toLowerCase().includes(keyword.toLowerCase()) ||
        state.contacts.every((contact) => {
          let fullName =
            contact.firstName +
            ' ' +
            (!contact.middleInitial ? '' : contact.middleInitial) +
            ' ' +
            contact.lastName;
          if (fullName.toLowerCase().includes(keyword.toLowerCase())) {
            return true;
          }
        })
      );
    });
    return state.filteredTickets;
  },

  setTicket(state, data) {
    return (state.ticket = data);
  },

  hoursToBill(state, data) {
    let totalHours = null;
    if (state.ticket.status === 5) {
      data.forEach((billing) => {
        totalHours += billing.hoursToBill;
      });
    } else {
      let totalHours = [];
    }
    return (state.hoursToBill = totalHours);
  },

  ticketNotes(state, data) {
    // Combine notes from data[0] and data[1], handling possible undefined/null values
    const notesArray1 = data[0] || [];
    const notesArray2 = data[1] || [];
    const combinedNotes = notesArray1.concat(notesArray2);

    // Sort on date and remove items with publish: 2 (internal notes)
    const sortedNotes = combinedNotes
      .slice() // Create a shallow copy to avoid modifying the original array
      .sort((a, b) => new Date(a.createDateTime) - new Date(b.createDateTime));

    const filteredNotes = sortedNotes.filter((item) => item.publish !== 2);
    // Update state.ticketNotes and local storage
    state.ticketNotes = filteredNotes;
  },

  newTicketNote(state, data) {
    state.ticketNotes.push(data);
  },

  fromDate(state, data) {
    // The start date value
    const startDate = data.fromDate;
    // The start date value
    // const startDate = data.fromDate;
    const startDateObj = new Date(startDate);
    // Get today's date and set the time to 0:00:00 to ignore time difference
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // Calculate the difference between the two dates in days
    const dateDifference = (today - startDateObj) / (1000 * 60 * 60 * 24);
    if (dateDifference > 31) {
      return (state.ticket.expiredObjection = true);
    } else {
      return (state.ticket.expiredObjection = false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
