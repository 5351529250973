import axios from 'axios';

const state = {
  projects: [],
  project: {},

  phases: [],
  tasks: [],

  dataLoaded: false,
  replyInProgress: false,
};

const getters = {
  projects: (state) => state.projects,
  project: (state) => state.project,

  phases: (state) => state.phases,
  tasks: (state) => state.tasks,

  dataLoaded: (state) => state.dataLoaded,
  replyInProgress: (state) => state.replyInProgress,
};

const actions = {
  projects(context, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `projects/${id}`)
        .then((response) => {
          context.commit('projects', response.data);

          let projectIds = response.data.map((project) => project.id);

          context.dispatch('phases', projectIds);
          context.dispatch('tasks', projectIds).then(() => {
            resolve(response.data);
          });
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  phases(context, ids) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `phases`, {
          params: {
            ids,
          },
        })
        .then((response) => {
          context.commit('phases', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  tasks(context, ids) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.rootState.domains.external_api + `tasks`, {
          params: {
            ids,
          },
        })
        .then((response) => {
          context.commit('tasks', response.data);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error.response.status);
        });
    });
  },

  replyOnTask(context, reply) {
    const data = new FormData();

    data.append('id', reply.taskId);
    data.append('projectId', reply.projectId);
    data.append('description', reply.description);
    data.append('totalDays', reply.totalDays);
    data.append('contactEmail', reply.contactEmail);

    for (let i = 0; i < reply.attachments.length; i++) {
      data.append('attachments[]', reply.attachments[i]);
    }

    return new Promise((resolve, reject) => {
      axios
        .post(context.rootState.domains.external_api + 'task/reply', data)
        .then((response) => {
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          // Handle error if necessary
          reject(error);
        });
    });
  },
};

const mutations = {
  projects(state, data) {
    state.projects = data;
  },

  project(state, data) {
    state.project = data;
    state.dataLoaded = true;
  },

  phases(state, data) {
    state.phases = data;
  },

  tasks(state, data) {
    state.tasks = data;
  },

  replyInProgress(state, data) {
    state.replyInProgress = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
