<template>
  <div class="table__container" :class="isTab || hasSelect ? 'shadow--no padding-0' : ''">
    <div class="table__column-picker">
      <i
        class="icon--grid"
        :class="hasSelect ? 'top-right' : ''"
        modal-id="columnPicker"
        @click="resetColumnPicker"
      ></i>
      <!-- COLUMN PICKER MODAL -->
      <modal :id="'columnPicker'" :title="'Kolom kiezer'" :width="511">
        <template v-slot:content>
          <div class="column-picker">
            <div class="column-picker__columns">
              <div class="column-picker__columns__title">
                <span class="h5 padding--bottom-0">Niet zichtbaar</span>
              </div>
              <!-- HIDDEN COLUMNS -->
              <div class="column-picker__columns__select" :key="reRender">
                <select
                  v-model="selectedColumns.hidden"
                  class="select--multiple overflow--scroll"
                  size="10"
                  multiple
                  aria-label="multiple"
                >
                  <option
                    v-for="(column, i) in filterColumns(hiddenColumns)"
                    :key="i"
                    :value="column"
                  >
                    {{ column.title }}
                  </option>
                </select>
              </div>
            </div>
            <!-- COLUMN VISIBILITY CHANGER -->
            <div class="column-picker__visibility-changer">
              <i
                @click="setColumnVisibility('hidden')"
                class="icon--arrow-left"
                :class="selectedColumns.visible.length ? 'cursor--pointer' : 'opacity-05'"
              ></i>
              <i
                @click="setColumnVisibility('visible')"
                class="icon--arrow-right"
                :class="selectedColumns.hidden.length ? 'cursor--pointer' : 'opacity-05'"
              ></i>
            </div>
            <!-- VISIBLE COLUMNS -->
            <div class="column-picker__columns">
              <div class="column-picker__columns__title">
                <span class="h5 padding--bottom-0">Zichtbaar</span>
              </div>
              <div class="column-picker__columns__select" :key="reRender">
                <select
                  v-model="selectedColumns.visible"
                  class="select--multiple overflow--scroll"
                  size="10"
                  multiple
                  aria-label="multiple"
                >
                  <option
                    v-for="(column, i) in filterColumns(visibleColumns)"
                    :key="i"
                    :value="column"
                  >
                    {{ column.title }}
                  </option>
                </select>
              </div>
            </div>
            <!-- COLUMN ORDER CHANGER -->
            <div class="column-picker__order-changer">
              <i class="icon--arrow-up" @click="changeColumnOrder('up')"></i>
              <i class="icon--arrow-down" @click="changeColumnOrder('down')"></i>
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button class="button button--color-blue" @click="columnsToDefault">
            Standaardinstellingen
          </button>
          <button class="button button--color-orange modal-button" @click="saveColumns">
            Opslaan
          </button>
        </template>
      </modal>
    </div>
    <!-- DATA TABLE ELEMENT -->
    <div :id="id + '-table'"></div>
  </div>
</template>

<script>
import Vue from 'vue';

import * as luxon from 'luxon';
window.luxon = luxon;

import {
  Tabulator,
  FormatModule,
  SortModule,
  EditModule,
  FilterModule,
  InteractionModule,
  FrozenColumnsModule,
} from 'tabulator-tables';
import { columnPresets } from '../../../assets/js/tableColumns';
import Page from '../../../assets/js/tabulator';

import Smiley from './Smiley.vue';

export default {
  props: {
    id: String,
    data: Array,
    columns: Array,
    defaultColumns: Array,
    // Function that's ran after data in table is processed.
    dataIsProcessed: Function,

    maxHeight: {
      type: [Number, Boolean],
      default: false,
    },
    // When "true" removes box-shadow from "table__container".
    isTab: {
      type: Boolean,
      default: false,
    },
    // When "true" put column picker icon in top right corner.
    hasSelect: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      filteredColumns: {},

      visibleColumns: [],
      hiddenColumns: [],
      selectedColumns: {
        visible: [],
        hidden: [],
      },

      initialSort: [],

      isRendered: true,
      reRender: 0,

      offsetHeight: 0,
      contentGap: 0,

      langs: {
        default: {
          pagination: {
            page_size: 'Per pagina',
            first: 'Eerste', //text for the first page button
            first_title: 'Eerste Pagina', //tooltip text for the first page button
            last: 'Laatste',
            last_title: 'Laatste Pagina',
            prev: '<',
            prev_title: 'Vorige Pagina',
            next: '>',
            next_title: 'Volgende Pagina',
          },
        },
      },
    };
  },

  created() {
    Tabulator.registerModule([
      Page,
      FormatModule,
      SortModule,
      EditModule,
      FilterModule,
      InteractionModule,
      FrozenColumnsModule,
    ]);

    this.setupTable();

    this.layoutHandler('create');
  },

  updated() {
    this.resetTable();
  },

  destroyed() {
    this.$table.destroy();
  },

  watch: {
    data: {
      handler: function (newData) {
        if (this.$table != undefined) {
          this.$table.replaceData(newData);
        }
      },
      deep: true,
    },
  },

  methods: {
    setupTable() {
      this.setupColumns().then(() => {
        Vue.prototype.$table = new Tabulator(`#${this.id}-table`, {
          data: this.data,
          columns: this.visibleColumns,
          initialSort: this.initialSort,

          placeholder: this.placeholder(),

          pagination: 'local',
          paginationSize: 10,
          paginationSizeSelector: [10, 25, 50, 100],
          paginationCounter: this.paginationCounter,
          paginationButtonCount: 3,

          locale: true,
          langs: this.langs,

          height: '100%',
          maxHeight: '100%',
          layout: 'fitDataFill',
          headerSortElement: function (column, dir) {
            switch (dir) {
              case 'asc':
                return "<i class='icon--sorter-asc'>";
                break;
              case 'desc':
                return "<i class='icon--sorter-desc'>";
                break;
              default:
                return "<i class='icon--sorter'>";
            }
          },
        });

        let self = this;

        this.$table.on('renderComplete', function () {
          if (self.isRendered) {
            self.setHeight();
          } else {
            self.isRendered = true;
          }

          if (self.dataIsProcessed != undefined) {
            self.dataIsProcessed();
          }
        });

        this.$table.on('dataFiltered', function (filters, rows) {
          if (filters.length) {
            self.setHeight(rows.length);
          }
        });

        var timer = null;
        window.addEventListener('resize', () => {
          clearTimeout(timer);
          timer = setTimeout(() => self.setHeight(), 200);
        });
      });
    },

    resetTable() {
      this.$table.destroy();

      this.setupTable();
    },

    setupColumns() {
      return new Promise((resolve, reject) => {
        this.initialSort = [];

        this.columns.forEach((column, i) => {
          this.columns[i] = this.setupColumn(column.type, column, i);

          if (i + 1 === this.columns.length) {
            resolve();
          }
        });

        this.visibleColumns = this.columns.filter((column) => column.visible);
        this.hiddenColumns = this.columns.filter((column) => !column.visible);

        this.reRender += 1;
      });
    },

    setupColumn(type, column, position) {
      let preset = {};

      if (columnPresets[type]) {
        Object.assign(preset, columnPresets[type]);
      }

      preset.field = column.field;
      preset.title = column.title;
      preset.visible = column.visible;

      preset.type = type;

      if (column.dir != undefined) {
        this.initialSort.push({ column: column.field, dir: column.dir });
      }

      if (preset.position == undefined) {
        preset.position = position;
      }

      if (column.formatter) {
        preset.formatter = column.formatter;
        preset.formatterParams = column.formatterParams;
      }

      if (!preset.title.length) {
        preset.headerFilter = false;
        preset.headerSort = false;
      } else {
        preset.headerFilter = 'input';
        preset.headerFilterPlaceholder = 'Zoek...';
      }

      switch (type) {
        case 'text':
          if (column.cellClick) {
            preset.cellClick = column.cellClick;
            preset.cssClass = 'text-blue-200 cursor--pointer';
          }
          break;
        case 'currency':
          preset.formatter = this.price;
          break;
        case 'checkbox':
          preset.formatter = column.formatter;
          preset.formatterParams = column.formatterParams;

          if (!preset.title.length) {
            preset.cssClass = 'checkbox-header';
            preset.headerHozAlign = 'left';
            preset.titleFormatter = column.titleFormatter;
            preset.titleFormatterParams = column.titleFormatterParams;

            preset.position = 'start';
          }

          break;
        case 'button':
          preset.formatter = this.button;
          preset.formatterParams = column.formatterParams;

          if (!preset.title.length) {
            preset.cssClass = 'button-header';
            preset.headerHozAlign = 'center';
            preset.titleFormatter = this.button;
            preset.titleFormatterParams = column.titleFormatterParams;

            preset.position = 'end';
          }

          break;
        case 'download':
          preset.formatter = this.download;
          preset.formatterParams = column.formatterParams;

          preset.position = 'end';
          break;
        case 'checkmark':
          if (column.formatter == undefined) {
            preset.formatter = this.checkmark;
          } else {
            preset.formatter = column.formatter;
          }

          break;
      }

      return preset;
    },

    saveColumns() {
      this.selectedColumns.visible = [];
      this.selectedColumns.hidden = [];

      // Show or hide columns that have their visibility changed, and also change the column position.
      this.columns.forEach((column, i) => {
        let visibleIndex = this.visibleColumns.findIndex(
          (item) => item.title == column.title && item.field == column.field
        );
        let hide = this.hiddenColumns.some(
          (item) => item.title == column.title && item.field == column.field
        );

        if (visibleIndex >= 0) {
          this.columns[i].visible = true;

          if (column.position == 'start' || column.position == 'end') {
            return;
          }
          this.columns[i].position = visibleIndex;
        } else if (hide) {
          this.columns[i].visible = false;
        }
      });

      // Sort columns on position.
      this.columns.sort((a, b) => a.position - b.position);

      this.layoutHandler('save');

      this.resetTable();
    },

    columnsToDefault() {
      this.columns.forEach((column, i) => {
        const defaultColumn = this.defaultColumns.find(
          (item) => item.title == column.title && item.field == column.field
        );

        this.columns[i] = { ...defaultColumn };
      });

      this.visibleColumns = this.columns.filter((column) => column.visible);
      this.hiddenColumns = this.columns.filter((column) => !column.visible);

      this.reRender += 1;
    },

    filterColumns(columns) {
      if (columns.length) {
        const filtered = columns.filter((column) => {
          if (
            (column.type == 'checkbox' && !column.title.length) ||
            column.type == 'button' ||
            column.type == 'download'
          ) {
            return false;
          } else {
            return true;
          }
        });

        return filtered;
      } else {
        return columns;
      }
    },

    setColumnVisibility(type) {
      // Set all selected visible columns to hidden.
      if (type == 'hidden' && this.selectedColumns.visible.length) {
        this.selectedColumns.visible.forEach((selectedColumn, i) => {
          // Add column to list of hidden columns
          this.hiddenColumns.splice(selectedColumn.position, 0, selectedColumn);

          // Remove column from visible columns
          this.visibleColumns = this.visibleColumns.filter((item) => {
            if (item.title == selectedColumn.title && item.type == selectedColumn.type) {
              return false;
            } else {
              return true;
            }
          });
        });
        this.selectedColumns.visible = [];
      }
      // Set all selected hidden columns to visible
      else if (type == 'visible' && this.selectedColumns.hidden.length) {
        this.selectedColumns.hidden.forEach((selectedColumn, i) => {
          // Add column to list of visible columns
          this.visibleColumns.splice(selectedColumn.position, 0, selectedColumn);

          // Remove column from visible columns
          this.hiddenColumns = this.hiddenColumns.filter((item) => {
            if (item.title == selectedColumn.title && item.type == selectedColumn.type) {
              return false;
            } else {
              return true;
            }
          });
        });

        this.selectedColumns.hidden = [];
      }
    },

    changeColumnOrder(direction) {
      this.selectedColumns.visible.forEach((selectedColumn, i) => {
        // Filter out specific columns that can't be moved.
        let visibleColumns = this.filterColumns(this.visibleColumns);

        const index = visibleColumns.findIndex(
          (item) => item.title == selectedColumn.title && item.type == selectedColumn.type
        );

        // When up arrow is clicked and index of selected column is higher then 0 move the column one position up. (this is for a visual response)
        if (direction == 'up' && index > 0) {
          let item = visibleColumns.splice(index, 1)[0];

          visibleColumns.splice(index - 1, 0, item);
        }
        // When down arrow is clicked and index of selected column is lower then total length of visible columns move the column one position down. (this is for a visual response)
        else if (direction == 'down' && index < visibleColumns.length - 1) {
          let item = visibleColumns.splice(index, 1)[0];

          visibleColumns.splice(index + 1, 0, item);
        }

        this.visibleColumns = visibleColumns;
      });

      this.reRender += 1;
    },

    resetColumnPicker() {
      this.visibleColumns = this.columns.filter((column) => column.visible);
      this.hiddenColumns = this.columns.filter((column) => !column.visible);

      this.reRender += 1;
    },

    /* FORMATTER FUNCTIONS BEGIN */
    button(cell, params) {
      let cellEl = cell.getElement();
      let value = cell.getValue();

      cellEl.style.padding = '7px 10px';

      let button = document.createElement('button');

      if (value != undefined && value != '&nbsp;') {
        button.id = params.id + value;
      } else if (value == '&nbsp;') {
        button.id = params.id;
      }

      button.innerText = params.text;

      button.classList.add('button', 'button--color-' + params.color);

      button.addEventListener('click', function () {
        params.clickHandler(cell.getValue());
      });

      return button;
    },

    download(cell, params) {
      let icon = document.createElement('i');

      icon.classList.add('icon--download');

      icon.addEventListener('click', function () {
        params.clickHandler(cell.getValue());
      });

      return icon;
    },

    checkmark(cell) {
      const value = cell.getValue();

      if (value) {
        return "<i class='icon--checkmark'></i>";
      } else {
        return '';
      }
    },

    price(cell) {
      // Format the number according to the Dutch locale with Euro currency
      return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(
        cell.getValue()
      );
    },

    /* FORMATTER FUNCTIONS END */

    paginationCounter(pageSize, currentRow, currentPage, totalRows, totalPages) {
      let counter = currentPage + ' / ' + totalPages;

      return counter;
    },

    /* DYNAMIC HEIGHT FUNCTIONS BEGIN */
    findSiblingElements(element) {
      // First child of main content element.
      const contentElement = document.querySelector('.content');

      // Stop running function when content element is reached.
      if (element == contentElement) {
        return;
      }

      // Run again with parent element when height of current element is the same as parent element.
      if (element.offsetHeight == element.parentNode.offsetHeight) {
        this.findSiblingElements(element.parentNode);
      } else {
        // Loop through all child nodes
        element.parentNode.childNodes.forEach((child) => {
          // when child is not equal to current element, use child height to calculate the total offset height.
          if (child != element) {
            const totalChildHeight = this.calculateTotalHeight(child);

            this.offsetHeight += totalChildHeight;
          }
          // When child is equal to current element, and parent of child not equal to child of main content (Without there is no stopping point), or child isn't the table__container run again.
          else if (
            (child == element && child.parentNode != contentElement.firstChild) ||
            child.classList.contains('table__container')
          ) {
            this.findSiblingElements(element.parentNode);
          }
        });
      }

      // Compute the style of the element to access CSS properties.
      const style = getComputedStyle(contentElement.firstChild);
      // Extract the row gap computed style.
      let rowGap = parseFloat(style.rowGap.replace('px', ''));

      if (style.rowGap == 'normal') {
        rowGap = 0;
      } else if (rowGap > 1) {
        // Account for a row gap in the offset height, based on the amount of children the content has.
        this.contentGap = rowGap * contentElement.firstChild.childNodes.length;
      }
    },

    calculateTotalHeight(element) {
      if (element.nodeName == '#comment') {
        return 0;
      }

      // Compute the style of the element to access CSS properties.
      const style = getComputedStyle(element);

      // Extract the row gap computed style.
      let rowGap = parseFloat(style.rowGap.replace('px', ''));

      if (typeof style.rowGap == 'string') {
        rowGap = 0;
      }
      // Extract the margin computed style.
      const marginTop = parseFloat(style['margin-top'].replace('px', ''));
      const marginBottom = parseFloat(style['margin-bottom'].replace('px', ''));

      const margin = marginTop + marginBottom;

      return element.offsetHeight + rowGap + margin;
    },

    // Method to adjust the table height based on the content and available space.
    setHeight(rows) {
      const table = document.querySelector('.tabulator-table');
      const tableHolder = document.querySelector('.tabulator-tableholder');
      const tableFooter = document.querySelector('.tabulator-footer');

      // Add extra height to footer when there is a vertical scroll visible.
      if (tableHolder.offsetWidth < table.offsetWidth) {
        tableFooter.style.height = '32px';
      } else {
        tableFooter.style.height = '22px';
      }

      // Determine the number of rows to display based on the table's page size and available data.
      let displayRows = 0;
      if (rows != undefined) {
        displayRows = rows;
      } else {
        displayRows = this.$table.getPageSize();

        if (displayRows > this.data.length) {
          displayRows = this.data.length;
        }
      }

      const tableContainer = document.querySelector('.table__container');

      this.findSiblingElements(tableContainer);

      // Calculate the heights of the table header, footer and row.
      const headerHeight = document.querySelector('.tabulator-header')?.offsetHeight;
      const footerHeight = tableFooter?.offsetHeight;
      const tableRowHeight = document.querySelector('.tabulator-row')?.offsetHeight;

      // Set the height to a set height when there is no data to be shown.
      if (tableRowHeight == undefined) {
        this.isRendered = false;

        this.$table.setHeight(414);

        this.$table.redraw(true);

        return;
      }

      // Combine header and footer heights into a single offset value.
      const heightOffset = headerHeight + footerHeight;

      // Calculate the table height by incrementing the number of display rows by table row height.
      const tableHeight = displayRows * tableRowHeight + 10;

      // Calculate the total height by adding the height offset to the table height.
      let newHeight = tableHeight + heightOffset;

      let contentHeight =
        this.calculateTotalHeight(document.querySelector('.content').firstChild) -
        this.contentGap -
        10;

      const mainTable = document.querySelector('.tabulator');

      if (newHeight > contentHeight) {
        newHeight = Math.abs(this.offsetHeight - contentHeight);

        mainTable.classList.add('has-overflow');
      } else if (newHeight < contentHeight) {
        mainTable.classList.remove('has-overflow');
      }
      // Prevent potential infinite loop by setting a flag.
      this.isRendered = false;
      this.offsetHeight = 0;

      // Apply the calculated height to the table and force a redraw to update the layout.
      if (this.maxHeight) {
        this.$table.setHeight(this.maxHeight);
      } else {
        this.$table.setHeight(newHeight);
      }

      this.$table.redraw(true);
    },
    /* DYNAMIC HEIGHT FUNCTIONS END */

    layoutHandler(method) {
      let layout = this.$user.data_table_layouts.find((layout) => layout.name == this.id);

      let columns = this.columns.map((column) => {
        return {
          title: column.title,
          field: column.field,
          visible: column.visible,
          position: column.position,
        };
      });

      switch (method) {
        case 'create':
          if (layout == undefined) {
            this.$store.dispatch('global/createDataTableLayout', {
              user_id: this.$user.id,
              name: this.id,
              columns: columns,
            });
          }
        case 'save':
          if (layout != undefined) {
            this.$store.dispatch('global/updateDataTableLayout', {
              user_id: this.$user.id,
              id: layout.id,
              columns: columns,
            });
          }

          break;

        default:
          break;
      }
    },

    placeholder() {
      if (this.id == 'openTickets' && !this.data.length) {
        var clickHandler = () => this.navigateToClosedTickets();

        // Create a render function for the Smiley component
        const renderPlaceholder = (h) =>
          h(Smiley, {
            props: {
              captionTitle: 'No open tickets',
              caption: 'Go To',
              captionLink: 'closed ticket',
              clickHandler: clickHandler,
            },
          });

        // Render the Smiley component directly to table
        const vueInstance = new Vue({ render: renderPlaceholder });
        vueInstance.$mount();

        return vueInstance.$el;
      } else {
        return 'Geen data beschikbaar';
      }
    },

    navigateToClosedTickets() {
      this.$router.push({ name: 'completed_tickets' });
    },
  },
};
</script>

<style></style>
