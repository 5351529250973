export const en = {
  global: {
    settings: 'Settings',
    save: 'Save',
    close: 'Close',
    send: 'Send',
    accept: 'Accept',
    reject: 'Reject',
    delete: 'Delete',
    user: 'User',
    days: 'Days',
    month: 'Month',
    date: 'date',
    number: 'number',
    open: 'Open',
    closed: 'Closed',
    end: 'End',
    back: 'Back',
  },
  status: {
    inProgress: 'In progress',
    actionRequired: 'Action required',
    scheduled: 'Scheduled',
    complete: 'Complete',
    cancelled: 'Cancelled',
    approvalPending: 'Approval pending',
    accepted: 'Accepted',
  },
  notification: {
    resetPassword: {
      success: 'Your password has been reset',
      error: 'Failed to reset your password',
    },
    ticketReceived: 'Your ticket has been successfully received',
    messageSend: 'Your message has been successfully sent',
    quote: {
      accepted: 'The quote has been successfully accepted',
      rejected: 'The quote has been successfully rejected',
    },
    reaction: {
      empty: 'Please enter a comment',
      send: 'Thanks for your reaction',
    },
    ticket: {
      open: 'The ticket has been successfully reopened',
      closed: 'The ticket has been closed successfully',
    },
    objectionSend:
      'Your objection has been successfully submitted, we will contact you as soon as possible',
  },
  label: {
    title: 'Title',
    description: 'Description',
    name: 'Name',
    attachment: 'Attachment',
  },
  login: {
    email: 'Email or username',
    password: 'Password',
    login: 'Login',
    emailAddress: 'Email address',
    requestPassword: 'Request new password',
    forgotPassword: 'Forgot Password?',
    forgotPasswordIsSent:
      'You have requested a new password. A link to change the password has been sent to:',
    or: 'Or',
    support: 'Support needed?',
    for: 'For',
    created: 'Created by',
    terms: 'Terms of use',
    privacy: 'Privacy policy',
    contact: 'Contact us',
    newPassword: 'New password',
    secondPassword: 'Repeat new password',
    submitNewPassword: 'Set new password',
  },
  header: {
    profile: 'Profile',
    signOut: 'Sign out',
    searchPlaceholder: 'Search Tickets...',
  },
  menu: {
    dashboard: 'Dashboard',
    tickets: 'Tickets',
    newTicket: 'New',
    projects: 'Projects',
    quotes: 'Quotes',
    invoices: 'Invoices',
  },
  footer: {
    lastSync: 'Last synchronized at',
  },
  datatable: {
    placeholder: 'No data found',
    columns: 'Columns',
  },
  request: {
    submit: 'Submit',
    perUnit: 'Per Unit',
    price: 'price',
  },
  tickets: {
    ticketTypes: 'Ticket types',
    allTickets: 'All tickets',
    openTickets: 'Open tickets',
    closedTickets: 'Closed tickets',
  },
  ticket: {
    created: 'Created',
    notes: 'Notes',
    attachments: 'Attachments',
    addNote: 'Add note',
  },
  projects: {
    lead: 'lead',
    waitingOnCustomer: 'Waiting on customer (workdays)',
    waitingFor: 'Waiting for',
  },
  widgetSettings: {
    ticketTitle: 'Ticket settings',
    serviceTitle: 'Service chooser',
    notVisible: 'Not visible',
    visible: 'Visible',
  },
  services: {
    services: 'Services',
    domainHosting: 'Domain & Hosting',
    electronicSecurity: 'Electronic Security',
    backupServices: 'Backup Services',
    cloudSolutions: 'Cloud Solutions',
    connectivity: 'Connectivity',
    emailServices: 'Email Services',
    managedItServices: 'Managed IT Services',
    securitySolutions: 'Security Solutions',
    telephony: 'Telephony',
    software: 'Software',
  },
  quotes: {
    quote: 'Quote',
    expiration: 'Expiration',
    beingPrepared: 'Being prepared',
    olderQuotes: 'Click here to view older quotes',
    acceptQuote:
      "By clicking 'Accept', you confirm your agreement to the Quote and its terms. This serves as proof of your consent",
    rejectQuote:
      "By clicking 'Reject', you confirm your rejection to the Quote and its terms. This serves as proof of your consent.",
  },
  subscriptions: {
    category: 'Category',
    startDate: 'Start Date',
  },
};
