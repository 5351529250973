import axios from 'axios';

const state = {
  conforms: [],

  totalDiscount: 0,

  loading: false,
};

const getters = {
  loading: (state) => state.loading,

  conforms: (state) => state.conforms,

  totalDiscount: (state) => state.totalDiscount,
};

const actions = {
  conforms(context, id) {
    axios.get(context.rootState.domains.users_api.cms + `conforms/${id}`).then((response) => {
      if (response.data.length) {
        context.commit('setConforms', response.data.data);
        context.commit('setTotalDiscount', response.data.data);
      }
    });
  },
};

const mutations = {
  setConforms(state, data) {
    state.loading = true;
    state.conforms = data;
  },

  setTotalDiscount(state, data) {
    state.totalDiscount = 0;

    data.forEach((conform) => {
      if (conform.show_discount) {
        state.totalDiscount = parseFloat(conform.discount) + state.totalDiscount;
      }
    });

    return state.totalDiscount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
