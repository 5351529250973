export const columnPresets = {
  text: {
    title: '',
    field: '',
    minWidth: 150,
    maxWidth: 450,
    hozAlign: 'left',
    sorter: 'string',
  },
  number: {
    title: '',
    field: '',
    minWidth: 100,
    maxWidth: 150,
    hozAlign: 'right',
    sorter: 'number',
    sorterParams: {
      thousandSeparator: '.',
      decimalSeparator: ',',
    },
  },
  hours: {
    title: '',
    field: '',
    minWidth: 100,
    maxWidth: 150,
    hozAlign: 'right',
  },
  date: {
    title: '',
    field: '',
    minWidth: 100,
    maxWidth: 150,
    hozAlign: 'right',
    sorter: 'date',
    sorterParams: {
      format: 'dd-MM-yyyy',
    },
  },
  dateTime: {
    title: '',
    field: '',
    minWidth: 150,
    maxWidth: 150,
    hozAlign: 'right',
    sorter: 'datetime',
    sorterParams: {
      format: 'HH:mm dd-MM-yyyy',
    },
  },
  checkbox: {
    title: '',
    field: '',
    minWidth: 34,
    maxWidth: 150,
    hozAlign: 'center',
  },
  checkmark: {
    title: '',
    field: '',
    minWidth: 34,
    maxWidth: 150,
    hozAlign: 'center',
  },
  currency: {
    title: '',
    field: '',
    minWidth: 100,
    maxWidth: 150,
    hozAlign: 'right',
    formatter: 'money',
    formatterParams: {
      decimal: ',',
      thousand: '.',
      symbol: '€',
      negativeSign: true,
    },
  },
  input: {
    title: '',
    field: '',
    minWidth: 50,
    maxWidth: 100,
    hozAlign: 'right',
    vertAlign: 'middle',
  },
  status: {
    title: '',
    field: '',
    minWidth: 100,
    maxWidth: 150,
  },
  download: {
    title: '',
    field: '',
    minWidth: 34,
    maxWidth: 150,
    frozen: true,
    hozAlign: 'center',
    vertAlign: 'middle',
  },
  button: {
    title: '',
    field: '',
    minWidth: 100,
    maxWidth: 150,
    frozen: true,
    hozAlign: 'left',
  },
  paid: {
    title: '',
    field: '',
    minWidth: 100,
    maxWidth: 150,
    hozAlign: 'left',
  },
};
