<template>
  <div :class="classHandler()">
    <canvas :id="id"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  props: {
    id: String,
    type: String,
    data: Object,
    options: Object,
    plugins: Array,
    height: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 0,
    },
  },

  mounted() {
    //  Chart.defaults.backgroundColor = '#9BD0F5';
    //  Chart.defaults.borderColor = '#36A2EB';
    Chart.defaults.color = '#009EDF';

    Chart.defaults.font.family = 'Forma DJR Micro';
    Chart.defaults.font.size = 20;
    Chart.defaults.font.weight = 'normal';

    this.setupChart();
  },

  methods: {
    setupChart() {
      let chartEl = document.getElementById(this.id);

      new Chart(chartEl, {
        type: this.type,
        data: this.data,
        options: this.options,
        plugins: this.chartPlugins(),
      });
    },

    chartPlugins() {
      let doughnutLabel = {
        id: 'doughnutLabel',
        afterDatasetDraw: function (chart, args, pluginOptions) {
          const { ctx, data } = chart;

          ctx.save();
          const textLabel = chart.getDatasetMeta(0)._dataset.label;

          const xCoor = chart.getDatasetMeta(0).data[0].x;
          const yCoor = chart.getDatasetMeta(0).data[0].y;
          ctx.font = pluginOptions.font;
          ctx.fillStyle = pluginOptions.color;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(textLabel, xCoor, yCoor + 2);
        },
      };

      const emptyDoughnut = {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
          const { datasets } = chart.data;
          const { color, width, radiusDecrease } = options;
          let hasData = false;

          for (let i = 0; i < datasets.length; i += 1) {
            const dataset = datasets[i];

            hasData |= dataset.data.length > 0;
          }

          if (!hasData || datasets[0].data[0] == 0) {
            const {
              chartArea: { left, top, right, bottom },
              ctx,
            } = chart;

            const centerX = (left + right) / 2;
            const centerY = (top + bottom - 4) / 2;
            const r = Math.min(right - left, bottom - top) / 2;

            ctx.beginPath();
            ctx.lineWidth = width || 2;
            ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
            ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
            ctx.stroke();
          }
        },
      };

      let plugins = [];

      this.plugins.forEach((plugin) => {
        if (plugin == 'doughnutLabel') {
          plugins.push(doughnutLabel);
        } else if (plugin == 'emptyDoughnut') {
          plugins.push(emptyDoughnut);
        }
      });

      return plugins;
    },

    classHandler() {
      let widthClass = 'width-px-' + this.width;
      let heightClass = 'height-px-' + this.height;

      return widthClass + ' ' + heightClass;
    },
  },
};
</script>

<style></style>
