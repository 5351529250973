<template>
  <div class="tabs">
    <div class="tabs__items">
      <div
        class="tabs__items__item"
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ 'tabs__items__item--active': activeTab === index + 1 }"
        @click="setActiveTab(index)"
      >
        {{ tab }}
      </div>
    </div>
    <div class="tabs__content">
      <slot :name="activeTab"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 1,
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setActiveTab(index) {
      this.activeTab = index + 1;

      this.$emit('tabChanged', this.activeTab);
    },
  },
};
</script>
